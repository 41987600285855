/* eslint-disable import/no-extraneous-dependencies */
import { ConfigProvider } from '@didi-pebble/pebble-design-react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { init } from '@didi/elvish-lib-javascript';
import zhCN from 'antd/es/locale/zh_CN';
import enUS from 'antd/es/locale/en_US';
import ptBR from 'antd/es/locale/pt_BR';

import { sentryInit } from '@/lib';
import '@/assets/styles/normalize.css';
import '@/assets/styles/common.less';
import '@/assets/styles/entregaNormalize.css';
import '@/assets/styles/icons.less';

import App from './App';
import { JsonParse, generateUniqueKey } from './utils';

const providerLocale: any = {
  'en-US': enUS,
  'zh-CN': zhCN,
  'pt-BR': ptBR
};
// dayjs多语言

// 初始化Sentry
sentryInit();

// 初始化elvish
init('pt-BR', 'BR');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if (!localStorage.getItem('web_id')) localStorage.setItem('web_id', generateUniqueKey());
try {
  const version = document.querySelector('meta[name="FE-VERSION"]')?.getAttribute('content');

  version && localStorage.setItem('b2c_web_version', version);
} catch (e) {
  console.log(e);
}

root.render(
  <ConfigProvider
    locale={
      providerLocale?.[
        JsonParse(localStorage.getItem('commonLang') as string)?.lang?.value || 'pt-BR'
      ]
    }
    theme={{
      token: {
        colorBgLayout: '#fff'
      },
      components: {
        Layout: {
          colorBgHeader: '#fff'
        },
        Collapse: {
          colorBgContainer: '#F8F8FA',
          colorBorder: '#E9EAEE'
        }
      }
    }}
  >
    <BrowserRouter basename={process.env.routerBaseName || ''}>
      <App />
    </BrowserRouter>
  </ConfigProvider>
);
