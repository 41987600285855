import { Modal, Popover } from '@didi-pebble/pebble-design-react';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { $t } from '@/lib/i18n';
import { JsonParse, JsonStringify } from '@/utils';
import {
  CityOpenCityOptionType,
  CityOpenCountryOptionType,
  cityOpenOptionsAtom
} from '@/store/common';
import { SessionType, defaultCity, defaultCountry } from '@/configs';
import { CommonApiModule } from '@/api/common.api';

import { MenuConfig, MenuDict } from '../page/config';

const CitySelect: React.FC = () => {
  const location = useLocation();
  const commonCity = JsonParse(localStorage.getItem('commonCity') as string);
  const [cityOpenOptionsValue, setCityOpenOptionsValue] = useAtom(cityOpenOptionsAtom);
  const [curCounrty, setCurCountry] = useState<CityOpenCountryOptionType | undefined>(
    commonCity?.country
  );
  const [curCity, setCurCity] = useState<CityOpenCityOptionType | undefined>(commonCity?.city);
  const [open, setOpen] = useState<boolean>(false);

  const resetCity = () => {
    const selectCountry = defaultCountry;
    const selectCity = defaultCity;

    localStorage.setItem(
      'commonCity',
      JsonStringify({
        city: selectCity,
        country: selectCountry
      })
    );
    setCurCountry(selectCountry);
    setCurCity(selectCity);
  };

  /** 获取顶部开城选项 */
  const getCityOpenOptions = async () => {
    try {
      const { data } = await CommonApiModule.getOpen();

      setCityOpenOptionsValue(data?.countries);
      const tempContry = data?.countries;
      const { country, city } = JsonParse(localStorage.getItem('commonCity') as string) || {};

      const selectCountry = tempContry?.find(item => item?.country_code === country?.country_code);
      const selectCity = selectCountry?.cities?.find(item => item?.city_id === city?.city_id);

      if (!selectCountry || !selectCity) {
        resetCity(); // 选中城市不在开城列表中，则重置城市
        window.location.reload(); // 刷新页面
      } else {
        setCurCountry(selectCountry);
        setCurCity(selectCity);
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  useEffect(() => {
    getCityOpenOptions();
  }, []);

  useEffect(() => {
    if (!commonCity) {
      resetCity();
    }
  }, [commonCity]);

  return (
    <div className='w-full h-full flex items-center'>
      <Popover
        overlayInnerStyle={{ padding: '0px' }}
        placement='bottomRight'
        trigger='click'
        open={open}
        onOpenChange={(v: boolean) => {
          setOpen(v);
        }}
        content={() => {
          return (
            <div className='w-[400px] flex justify-between text-[#595C66] text-sm leading-5 font-DiDiSansPro-Regular'>
              <div className='w-1/2 py-1 max-h-[328px] overflow-auto'>
                {cityOpenOptionsValue?.map((country: CityOpenCountryOptionType) => {
                  return (
                    <div
                      className={`px-3 py-[10px] cursor-pointer ${
                        country.country_code === curCounrty?.country_code &&
                        'bg-[#FFF7F2] text-[#F76A31] font-DiDiSansPro-Medium'
                      } hover:bg-gray-100`}
                      key={country.country_code}
                      onClick={() => {
                        setCurCountry(country);
                      }}
                    >
                      <div className='flex items-center'>
                        <div className='img_icon icon_br'></div>
                        <div className='ml-2 leading-5'>{country.country_name || '请选择'}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className='w-[1px] bg-[#E9EAEE]'></div>
              <div className='w-1/2 py-1 max-h-[328px] overflow-auto'>
                {curCounrty?.cities.map((city: CityOpenCityOptionType) => {
                  return (
                    <div
                      className={`px-3 py-[10px] cursor-pointer ${
                        city?.city_id === curCity?.city_id &&
                        'bg-[#FFF7F2] text-[#F76A31] font-DiDiSansPro-Medium'
                      } hover:bg-gray-100`}
                      key={city?.city_id}
                      onClick={() => {
                        setOpen(false);
                        if (location.pathname === MenuConfig[MenuDict.NewDeliver]?.routePath) {
                          Modal.confirm({
                            icon: null,
                            closable: true,
                            title:
                              $t('Entrega_B2C_new_delivery_confirm_modification_city') ||
                              '确认修改城市',
                            content:
                              $t(
                                'Entrega_B2C_new_delivery_confirm_modification_city_explanation'
                              ) || '修改城市后，将清空当前所填信息，确认要继续操作吗？',
                            cancelText:
                              $t(
                                'Entrega_B2C_new_delivery_confirm_modification_city_cancel_button'
                              ) || '取消修改',
                            okText:
                              $t(
                                'Entrega_B2C_new_delivery_confirm_modification_city_modify_button'
                              ) || '继续修改',
                            onOk: () => {
                              setCurCity(city);
                              localStorage.setItem(
                                'commonCity',
                                JsonStringify({
                                  city,
                                  country: curCounrty
                                })
                              );
                              sessionStorage.removeItem('newDeliver');
                              localStorage.removeItem('newDeliver');
                              sessionStorage.removeItem(SessionType.windowLeaveValue);
                              setOpen(false);
                              window.location.reload();
                            },
                            onCancel: () => {
                              setOpen(false);
                            },
                            centered: true
                          });
                        } else {
                          setCurCity(city);
                          localStorage.setItem(
                            'commonCity',
                            JsonStringify({
                              city,
                              country: curCounrty
                            })
                          );
                          sessionStorage.removeItem('newDeliver');
                          localStorage.removeItem('newDeliver');
                          setOpen(false);
                          window.location.reload();
                        }
                      }}
                    >
                      {city?.city_name}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }}
      >
        <div className={`flex items-center ${open ? 'border-b-2 border-b-[#F76A31]' : ''}`}>
          <div className='img_icon icon_br'></div>
          <div className='ml-2 text-xs font-DiDiSansPro-Regular'>
            {curCity?.city_name || '请选择'}
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default CitySelect;
