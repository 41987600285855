export default {
  "Entrega_B2C_settings_payment_methods_changepaymentmethodtoast3": "你已成功切换到Boleto支付方式",
  "Entrega_B2C_settings_payment_methods_changepaymentmethodtoast4": "Boleto支付方式暂不可用，你仍可以继续使用信用卡支付{{99Entrega}}服务",
  "Entrega_B2C_settings_payment_methods_changepaymentmethodtoast5": "你有账单尚未支付，暂时无法切换回信用卡支付。点击前往{{billspage}}",
  "Entrega_B2C_newdelivery_popupbutton2": "去支付",
  "Entrega_B2C_settings_payment_methods_changepaymentmethodtoast1": "你本月已经修改过一次支付方式，请下月再试",
  "Entrega_B2C_newdelivery_popupbutton1": "知道了",
  "Entrega_B2C_statement_actions_receipt": "订单信息",
  "Entrega_B2C_settings_payment_methods_changepaymentmethodtoast2": "Boleto暂时无法使用，请于{{date}}后重试",
  "Entrega_B2C_Accounting_Bills_Orderdetails_refund": "已退款",
  "Entrega_B2C_cannot_cancel_": "当前订单无法取消，请刷新订单信息",
  "Entrega_B2C_new_delivery_dropoff_information_validation": "请完善收件信息",
  "Entrega_B2C_Deliveries_list_ongoing": "进行中订单",
  "Entrega_B2C_Website_modifypassword": "修改密码",
  "Entrega_B2C_statement_AmountDue_fine": "罚金：{{fine}}",
  "Entrega_B2C_new_delivery_save_the_information_explanation": "离开前要保存当前页面所填写的内容吗？",
  "Entrega_B2C_Homepage_description_delivery_now": "立即发单",
  "Entrega_B2C_Registration_previousstep": "上一步",
  "New_Category_Apps_details_yHOm_EWmJ": "订单已取消，如有疑问请联系客服",
  "Entrega_B2C_new_delivery_dropoff_information_enter_contact_person_name": "输入收件人姓名",
  "Entrega_B2C_Website_Portuguese": "葡萄牙语",
  "Entrega_B2C_statement_AmountDue_interest": "利息：{{interest}}",
  "Entrega_B2C_Accounting_debitnote": "借记单",
  "Entrega_B2C_Accounting_Bills_list_PayID": "支付号",
  "Entrega_B2C_new_delivery_pickup_information_enter_complement": "输入地址补充信息",
  "Entrega_B2C_new_delivery_payment_failure_updated_price": "重试",
  "Entrega_B2C_Website_slogan": "{{99Entrega}}，满足你的需要",
  "Entrega_B2C_Website_EN": "英语",
  "Entrega_B2C_Deliveries_list_Histories": "已完成订单",
  "Entrega_B2C_statement_actions_boleto": "Boleto",
  "Entrega_B2C_address_limitaion": "地址补充信息过长，请修改",
  "Entrega_B2C_statement_duedate_updated": "已更新",
  "Entrega_B2C_settings_payment_methods_boleto_apply": "申请",
  "Entrega_B2C_Deliveries_search_fillinrecepientaddress": "请输入收件地址",
  "Entrega_B2C_Accounting_Bills_list_operation": "操作",
  "Entrega_B2C_statement_download_failed": "下载失败，请重试",
  "New_Category_Apps_Companyinfo_BHqW_SwQl": "邮箱",
  "Entrega_B2C_settings_payment_methods_pendingreview": "待审核",
  "Entrega_B2C_Accounting_debitnote_totalamount": "总金额",
  "Entrega_B2C_Homepage_description_newdelivery": "创建订单，开始使用我们的配送服务吧",
  "Entrega_B2C_Accounting_Bills_list_formofpayment": "支付方式",
  "New_Category_Apps_details_ayEA_eBGp": "取件码是包裹交付的凭证，请在配送员取货后提供。",
  "Entrega_B2C_Accounting_Bills_list_status": "支付状态",
  "Entrega_B2C_history_order_cancel_failure": "订单取消失败",
  "Entrega_B2C_Accounting_debitnote_numofruns": "总笔数",
  "Entrega_B2C_Deliveries_list_address": "取送件地址",
  "Entrega_order_history_end_date": "结束日期",
  "Entrega_B2C_Website_ordernow": "立即下单",
  "Entrega_B2C_statement_AmountDue_newamount": "新金额：{{new}}",
  "Entrega_B2C_settings_payment_methods_boleto_residentiallimit": "剩余额度：{{left}}",
  "Entrega_B2C_Registration_estimatedvolume": "每月预估单量",
  "Entrega_B2C_Accounting_Bills_bills": "账单",
  "New_Category_Apps_details_mQWr_dSmr": "订单已关闭",
  "EntregaB2C_settings_developer_testmode_apply_confirm": "确认",
  "Entrega_B2C_new_delivery_pickup_information_enter_contact_number_validation": "请输入正确的发件人电话号码",
  "Entrega_B2C_debit_note_sucess_download": "账单下载成功",
  "Entrega_B2C_Accounting_Bills_Orderdetails_refundamount": "退款金额",
  "Entrega_B2C_transactionhistory": "交易记录",
  "Entrega_B2C_Accounting_Bills_list_status_paid": "已支付",
  "Entrega_B2C_account": "公司信息",
  "Entrega_B2C_bills_view": "查看",
  "Entrega_B2C_Deliveries_search_recepientaddress": "收件地址",
  "Entrega_B2C_statement_billingnumber": "账单编号",
  "Entrega_B2C_new_delivery_total_price": "总金额",
  "New_Category_Apps_details_AWjw_cUlD": "配送员将于{{time}}到达，请准备寄送货品，保持电话畅通",
  "Entrega_B2C_Deliveries_list": "订单列表",
  "Entrega_B2C_settings_payment_methods_boleto_totallimit": "总额度：{{total}}",
  "Entrega_B2C_Homepage_description_Bills": "查看你的全部交易记录",
  "Entrega_B2C_Homepage_description_details": "详情",
  "Entrega_B2C_new_delivery_pickup_information_complement": "发件地址补充信息",
  "Entrega_B2C_new_delivery_payment_failure_updated_failed": "预估价格失败",
  "Entrega_B2C_new_delivery_save_the_information_leave_button": "不保存",
  "Entrega_B2C_Accounting_Bills_Orderdetails_total": "总金额{{amount}}",
  "Entrega_B2C_settings_payment_methods_boleto_paymentterm": "支付周期：{{paymentterm}}",
  "Entrega_B2C_new_delivery_dropoff_information_contact_number": "收件人电话",
  "Entrega_B2C_Registration_companyname2": "请填写公司名称",
  "Entrega_B2C_Registration_companyname1": "请填写公司名称",
  "Entrega_B2C_new_delivery_pending_order": "你有一笔待支付订单",
  "Entrega_B2C_new_delivery_add_pickup_details": "添加发件信息",
  "Entrega_B2C_directory_newdelivery": "创建订单",
  "Entrega_B2C_networkbreakdown_toast1": "操作失败，请稍后重试",
  "Entrega_B2C_networkbreakdown_retry": "重试",
  "Entrega_B2C_networkbreakdown_toast2": "网络异常，请刷新重试",
  "Entrega_B2C_Homepage_description_deliveris": "查看和管理你的全部订单",
  "New_Category_Apps_details_Zoin_QyVr": "送达时间{{time}}",
  "Entrega_B2C_Registration_complement1": "例如：公寓101",
  "Entrega_B2C_new_delivery_pickup_information_cancel": "取消",
  "New_Category_Apps_details_AbyF_NMKH": "等待配送员接单",
  "Entrega_B2C_Registration_risknote": "抱歉，你的公司信息异常，暂时无法注册",
  "Entrega_B2C_bills_reset": "重置",
  "Entrega_order_history_status": "状态",
  "Entrega_B2C_directory_payment": "支付信息",
  "Entrega_B2C_Deliveries_list_cancel": "取消",
  "Entrega_B2C_new_delivery_dropoff_information_enter_dropoff_address": "输入收件地址",
  "Entrega_B2C_new_delivery_advanced": "高级选项",
  "Entrega_B2C_new_delivery_order_cancel_timeout": "因超时未支付，你有一笔订单被取消",
  "Entrega_B2C_new_delivery_enter_pickup_information_validation": "请输入发件地址",
  "Entrega_B2C_Settings_registrationdate_company": "注册信息-公司信息",
  "Entrega_B2C_Deliveries_search_fillinrecepientphone": "请输入收件人手机号",
  "Entrega_B2C_directory_deliveries": "历史订单",
  "Entrega_B2C_networkbreakdown_toast": "系统异常，请刷新重试",
  "Entrega_B2C_new_delivery_pickup_information_contact_number": "发件人电话号码",
  "Entrega_B2C_debit_note_sucess_failure": "账单下载失败",
  "Entrega_B2C_new_delivery_pickup_time": "发件时间",
  "Entrega_B2C_statement_nodata": "暂无账单",
  "Entrega_B2C_Registration_companyaddress": "公司地址",
  "Entrega_B2C_Deliveries_search_fillinrecepientname": "请输入收件人姓名",
  "New_Category_Apps_details_gfOJ_AqBe": "收件码是包裹交付的凭证，收件人将在收货后提供给配送员。",
  "Entrega_B2C_new_delivery_dropoff_information_complement": "送件地址补充",
  "Entrega_B2C_Registration_zipcode": "邮编",
  "Entrega_B2C_Registration_complement": "补充",
  "Entrega_B2C_new_delivery_pickup_code_details": "请在骑手到达取件时，将四位数的取件码告知骑手",
  "Entrega_B2C_new_delivery_pickup_information_service_validation": "输入地址并非当前选中城市，请选择其他地址",
  "Entrega_B2C_Deliveries_list_price": "价格",
  "Entrega_B2C_settings_payment_methods_changetoboletopopup_title": "切换到Boleto支付",
  "Entrega_B2C_newdelivery_popuptitle1": "剩余额度不足",
  "Entrega_B2C_newdelivery_popuptitle2": "账单逾期未支付",
  "Entrega_B2C_Accounting_Bills_list_Payday": "支付时间",
  "New_Category_Apps_details_KSQo_BLSm": "收件码",
  "Entrega_B2C_Website_signup": "注册",
  "Entrega_B2C_new_delivery_dropoff_information_choose_package_weight": "请选择包裹重量",
  "New_Category_Apps_details_EQLy_QWsI": "订单号",
  "Entrega_B2C_Registration_city2": "请选择城市",
  "New_Category_Apps_details_tUHk_DCfd": "配送员已到达，请尽快交付货品",
  "Entrega_B2C_Registration_city1": "请选择城市",
  "Entrega_B2C_Registration_operationinfo": "业务信息",
  "Entrega_B2C_new_delivery_confirm_modification_city_explanation": "修改城市后，将清空当前所填信息，确认要继续操作吗？",
  "Entrega_B2C_Deliveries_list_emptylist": "暂无订单",
  "Entrega_B2C_Accounting_Bills_list_refundamount": "退款金额",
  "Entrega_B2C_Registration_failure3": "查看详情",
  "Entrega_B2C_Deliveries_search_status": "请选择状态",
  "Entrega_B2C_Registration_failure1": "你的公司信息核实失败",
  "Entrega_B2C_Registration_failure2": "点击下方查看失败原因，调整后可重新提交申请再次核实",
  "Entrega_B2C_history_order_code": "验证码",
  "Entrega_B2C_bills_search": "搜索",
  "Entrega_B2C_Registration_companyname": "公司名称",
  "Entrega_B2C_Accounting_Bills_Orderdetails": "支付详情",
  "Entrega_B2C__settings_payment_methods_managemycard": "管理",
  "Entrega_B2C_new_delivery_maximum_10_dropoff": "最多10个收件地址",
  "Entrega_B2C_Website_Portuguese1": "葡萄牙语",
  "Entrega_B2C_settings_payment_methods_boleto_billingcycle_tip": "每月第一个工作日生成账单",
  "Entrega_B2C_statement_duedate": "到期日",
  "Entrega_B2C_new_delivery_pending_order_explanation": "请先处理待支付订单后，再重新发单",
  "Entrega_B2C_new_delivery_order_now": "立即下单",
  "Entrega_B2C_new_delivery_add_dropoff_information": "添加收件信息",
  "Entrega_B2C_Accounting_Bills_Orderdetails_refundtime": "退款时间",
  "Entrega_B2C_new_delivery_pickup_address": "发件地址",
  "Entrega_B2C_settings_payment_methods_selectpaymentmethod": "请选择支付方式",
  "New_Category_Apps_details_ajoT_FBnL": "货品已送达",
  "Entrega_B2C_new_delivery_dropoff_information_category": "物品类型",
  "Entrega_B2C_Accounting_Bills_Orderdetails_moredetails": "更多详情",
  "Entrega_order_history_start_date": "开始日期",
  "Entrega_B2C_new_delivery_pickup_information_validation": "请完善取件信息",
  "Entrega_B2C_debitnote_paymentmethod": "支付方式",
  "Entrega_B2C_new_delivery_save_the_information": "保存信息",
  "Entrega_B2C_new_delivery_cancelwording": "请告诉我们你想取消订单的原因，我们会持续改进，为你提供更好的服务体验。如已用信用卡支付过配送费，退款将于7-15个工作日退回你的付款账户",
  "New_Category_Apps_details_HRjt_Qzfv": "订单已关闭，如有疑问请联系客服",
  "Entrega_B2C_Accounting_Bills_Orderdetails_totalamount": "总金额",
  "Entrega_B2C_new_delivery_pickup_information_enter_Notes": "附加信息：如商品尺寸（如果商品体积很大）或其他需要骑手在取件时需要注意的内容。该信息是分享给骑手的，请不要输入保密或者专有的信息",
  "Entrega_B2C_new_delivery_pickup_information_enter_contact_person_name_validation": "请输入发件人姓名",
  "New_Category_Apps_details_qIYs_kBzP": "正在寻找配送员，请耐心等待",
  "Entrega_B2C_newdelivery_insufficientresidualcredit": "余额不足，剩余额度：{{residual_credit}}",
  "Entrega_B2C_new_delivery_confirm_modification_city_modify_button": "继续修改",
  "Entrega_B2C_Registration_number": "街道编码",
  "New_Category_Apps_details_PfKj_ULAj": "货品配送中",
  "Entrega_B2C_Accounting_Bills_list_pay": "支付",
  "Entrega_B2C_Registration_zipcode2": "请填写邮编。邮编格式：XXXXX-XXX",
  "Entrega_B2C_Registration_zipcode1": "请填写邮编。邮编格式：XXXXX-XXX",
  "Entrega_B2C_new_delivery_dropoff_information_enter_contact_number": "输入收件人电话",
  "New_Category_Apps_details_UDqE_blno": "地址信息",
  "Entrega_B2C_new_delivery_save_the_information_save_button": "保存",
  "Entrega_B2C_new_delivery_confirm_modification_city": "确认修改城市",
  "Entrega_B2C_Registration_zipcode3": "邮编格式有误，请检查后重新填写",
  "Entrega_B2C_Deliveries_search_recepientphone": "收件人手机号",
  "Entrega_B2C_Registration_submittip3": "我们将于1-3个工作日内通过邮件通知你入驻结果，请注意查收",
  "Entrega_B2C_Registration_submittip2": "期待与你携手合作，协助你完成配送任务",
  "Entrega_B2C_Registration_submittip1": "提交成功",
  "New_Category_Apps_orders_XPKr_IJRz": "物品信息",
  "Entrega_B2C_new_delivery_dropoff_information_enter_Notes": "附加信息：如商品尺寸（如果商品体积很大）或其他需要骑手在送件时需要注意的内容。该信息是分享给骑手的，请不要输入保密或者专有的信息",
  "Entrega_B2C_new_delivery_dropoff_information_enter_dropoff_address_validation": "请输入收件地址",
  "New_Category_Apps_details_lggF_KuLf": "物品信息",
  "Entrega_B2C_Website_99Entrega": "闪送",
  "Entrega_B2C_Registration_city": "城市",
  "Entrega_B2C_statement_AmountDue": "应付金额",
  "Entrega_B2C_Website_introduction": "为你提供方便、快捷、安全的即时配送服务",
  "Entrega_B2C_Accounting_Bills_list_status_tobepaid": "待支付",
  "Entrega_B2C_statement_AmountDue_originalamount": "原金额：{{old}}",
  "Entrega_B2C_history_order_cancel_success": "订单取消成功",
  "Entrega_B2C_Deliveries_search_recepientname": "收件人姓名",
  "Entrega_B2C_Accounting_Bills_Orderdetails_priceinformation": "价格信息",
  "Entrega_B2C_new_delivery_code_details": "请将四位数的收件码告知收件人，方便收件人在骑手送达时告知骑手",
  "Entrega_B2C_Deliveries_list_pickuptime": "取件时间",
  "Entrega_B2C_new_delivery_delivery_code": "收件码",
  "Entrega_B2C_Registration_startusing5": "专业配送员保障你的商品安全送达，配送过程可实时追踪物品位置",
  "Entrega_B2C_new_delivery_dropoff_information_package_weight": "包裹重量",
  "Entrega_B2C_Registration_basicinfo": "公司信息",
  "New_Category_Apps_details_cprL_wHtG": "配送员信息",
  "Entrega_B2C_bills_date": "日期",
  "Entrega_B2C_new_delivery_pending_order_to_pay_button": "前往处理",
  "Entrega_B2C_new_delivery_dropoff_information_enter_contact_person_name_validation": "请输入正确的收件人姓名",
  "Entrega_B2C_statement_enterbillingnumber": "输入账单编号",
  "Entrega_B2C_Accounting_Bills_Orderdetails_orderID": "订单号",
  "Entrega_B2C_new_delivery_dropoff_information": "送件信息",
  "New_Category_Apps_details_FqMP_biOY": "取消订单",
  "Entrega_B2C_settings_payment_methods_changepaymentmethodpopup_content": "确认要申请Boleto支付吗？我们会在3个工作日内完成资质评估，并及时通知你",
  "Entrega_B2C_Registration_startusing4": "定价透明，基于距离计费，支持按单支付",
  "Entrega_B2C_Registration_startusing3": "为你公司的网站订单、电话订单、应用订单提供配送",
  "Entrega_B2C_Settings_registrationdate_mainuser_email": "电话",
  "Entrega_B2C_Registration_startusing2": "注册即可为顾客提供即时配送服务",
  "Entrega_B2C_new_delivery_cancel_button1": "取消订单",
  "Entrega_B2C_Registration_startusing1": "开始使用{{99Entrega}}",
  "Entrega_B2C_Homepage_description_settings": "管理你的系统设置",
  "Entrega_B2C_statement_status": "账单状态",
  "Entrega_B2C_new_delivery_dropoff_information_contact_person_name": "收件人姓名",
  "Entrega_B2C_new_delivery_cancel_button3": "返回",
  "New_Category_Apps_details_YLwR_BPBA": "取件码",
  "Entrega_B2C_new_delivery_dropoff_information_dropoff_address": "收件地址",
  "Entrega_B2C_new_delivery_cancel_button2": "我再想想",
  "Entrega_B2C_Accounting_debitnote_period": "账单周期",
  "Entrega_B2C_Registration_submit": "提交",
  "Entrega_B2C_new_delivery_pickup_information_enter_contact_person_name": "输入发件人姓名",
  "Entrega_B2C_new_delivery_cancel_button4": "取消订单",
  "Entrega_B2C_Homepage_description_Debitnote": "下载你的借记单",
  "Entrega_B2C_settings_payment_methods_changetocreditcardpopup_title": "切换到信用卡支付",
  "Entrega_B2C_Settings_registrationdate_company_reject": "入驻失败。失败原因：{{reject_reason}}",
  "EntregaB2C_settings_developer_onlinemode_cancel": "取消",
  "Entrega_B2C_Accounting_Bills_Orderdetails_orderinformation": "订单信息",
  "Entrega_B2C_new_delivery_dropoff_information_enter_contact_number_validation": "请输入正确的收件人电话",
  "Entrega_B2C_statement_duedate_originaldate": "原日期：{{old}}",
  "Entrega_B2C_new_delivery_add_dropoff": "增加收件地址",
  "Entrega_B2C_Registration_CNPJ": "税号",
  "New_Category_Apps_details_kOXX_JIxu": "等待配送员接单",
  "Entrega_B2C_Accounting_Bills_list_status_cancelled": "已取消",
  "Entrega_B2C_Accounting_Bills_list_cancelsuccess": "订单取消成功，请刷新页面",
  "Entrega_B2C_company_name_limitaion": "公司名称过长，请修改",
  "Entrega_B2C_settings_payment_methods_selectpaymentmethod_tip": "每月仅可修改一次支付方式",
  "Entrega_B2C_new_delivery_pickup_information_contact_person_name": "发件人姓名",
  "Entrega_B2C_new_delivery_confirm_modification_city_cancel_button": "取消修改",
  "Entrega_B2C_new_delivery_pickup_information_save": "保存",
  "Entrega_B2C_new_delivery_pickup_code": "取件码",
  "Entrega_B2C_Registration_nextstep": "下一步",
  "Entrega_B2C_Registration_estimatedvolume1": "请选择每月预估单量",
  "Entrega_B2C_Registration_estimatedvolume2": "请选择每月预估单量",
  "Entrega_B2C_directory_setting": "设置",
  "Entrega_B2C_settings_payment_methods_boleto_billingcycle": "账单周期：{{Billingcycle}}",
  "Entrega_B2C_statement_AmountPaid": "实付金额",
  "Entrega_B2C_newdelivery_residualcredit": "剩余额度：{{residual_credit}}",
  "Entrega_B2C_Deliveries_list_orderagain": "再来一单",
  "Entrega_B2C_Registration_number1": "请填写街道编码",
  "Entrega_B2C_Registration_number2": "请填写街道编码",
  "Entrega_B2C_new_delivery_pickup_time_now": "立即发件",
  "Entrega_B2C_Website_login": "登录",
  "Entrega_B2C_Website_logout": "登出",
  "Entrega_B2C_statement_bubble": "如您对账单有疑问，可点击此处联系客服",
  "Entrega_B2C_Registration_CNPJ4": "税号格式有误，请检查后重新填写",
  "Entrega_B2C_Registration_businesstype1": "请选择业务类型",
  "Entrega_B2C_Registration_businesstype2": "请选择业务类型",
  "Entrega_B2C_Registration_CNPJ2": "请填写税号",
  "Entrega_B2C_Registration_CNPJ1": "请填写税号。税号格式：XX.XXX.XXX/XXXX-XX",
  "Entrega_B2C_new_delivery_payment_failure_updated_failed_explanation": "本次未能成功预估订单价格，请点击按钮重试",
  "Entrega_B2C_directory_home": "主页",
  "Entrega_B2C_Deliveries_detail_ordercreationtime": "订单创建时间",
  "Entrega_B2C_Website_English": "英语",
  "New_Category_Apps_details_VmiH_KcYh": "预计送达时间{{time}}",
  "New_Category_Apps_details_BpIB_HITO": "附近配送员较少，请耐心等待",
  "Entrega_B2C_new_delivery_dropoff_information_enter_complement": "输入收件地址补充信息",
  "Entrega_B2C__settings_payment_methods_creditcard": "信用卡",
  "Entrega_B2C_statement": "账单",
  "Entrega_B2C_Settings_registrationdate_mainuser": "注册信息-用户信息",
  "Entrega_B2C_debitnote_selectpaymentmethod": "选择支付方式",
  "Entrega_B2C_settings_payment_methods_changetocreditcardpopup_content": "确认要切换为信用卡支付吗？如果切换回信用卡支付后，想再次使用Boleto，你需要重新申请并评估资质",
  "Entrega_B2C_Registration_companyaddress2": "请填写公司地址",
  "Entrega_B2C_new_delivery_vehicle_types": "车型",
  "Entrega_B2C_directory_payment_methods": "支付设置",
  "Entrega_B2C_Accounting_Bills_list_amountpaid": "支付金额",
  "New_Category_Apps_details_cSAS_LrRJ": "订单信息",
  "Entrega_B2C_new_delivery_enter_pickup_information": "输入发件地址",
  "Entrega_B2C_Registration_companyaddress1": "请填写公司地址",
  "Entrega_B2C_Registration_businesstype": "业务类型",
  "Entrega_B2C_Accounting_debitnote_nodebitnote": "暂无借记单",
  "Entrega_B2C_new_delivery_pickup_information_Notes": "备注",
  "Entrega_B2C_new_delivery_pickup_information": "发件信息",
  "Entrega_B2C_statement_duedate_newdate": "新日期：{{new}}",
  "Entrega_B2C_Accounting_Bills_Orderdetails_createtime": "订单创建时间：{{yyyymmddhh}}",
  "Entrega_B2C_statement_download_success": "下载成功",
  "Entrega_B2C_new_delivery_dropoff_information_category_validation": "请选择物品类型",
  "Entrega_B2C_newdelivery_popupcontent1": "你的账户剩余额度不足以支付本次配送费，无法发单",
  "Entrega_B2C_newdelivery_popupcontent2": "你有逾期账单尚未支付，无法继续使用配送服务，请付清后重试。如有账单疑问，请联系客服",
  "Entrega_B2C_Accounting_Bills_list_nobills": "暂无账单",
  "Entrega_B2C_new_delivery_cancel_title1": "取消订单",
  "Entrega_B2C_Deliveries_list_status_accepted": "已接单",
  "Entrega_B2C_new_delivery_cancel_title2": "确认要取消订单吗？",
  "Entrega_B2C_statement_selectstatus": "选择账单状态",
  "Entrega_B2C_new_delivery_order_information": "发单信息",
  "Entrega_B2C_delivery_account_block": "当前账号异常，暂时无法发单",
  "Entrega_B2C_notes_limitaion": "备注信息过长，请修改",
  "New_Category_Apps_details_VZqW_ZPZb": "订单已取消",
  "Entrega_B2C_Accounting_Bills_accounting": "财务管理",
  "Entrega_B2C_Accounting_Bills_Orderdetails_paytime": "支付时间：{{yyyymmddhh}}",
  "Entrega_B2C_new_delivery_cancelwording2": "如已用信用卡支付过配送费，退款将于7-15个工作日退回你的付款账户",
  "Entrega_B2C_settings_payment_methods_boleto": "Boleto",
  "Entrega_B2C_toast2": "暂无数据",
  "Entrega_B2C_toast1": "未获取到数据，请重试",
  "Entrega_B2C_Accounting_debitnote_download": "下载",
  "Entrega_B2C_new_delivery_pickup_information_enter_contact_number": "输入发件人电话号码",
  "Entrega_B2C_statement_actions_debitnote": "借记单",
  "Entrega_B2C_Deliveries_list_orderID": "订单号"
}