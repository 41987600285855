import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const sentryInit = () => {
  Sentry.init({
    // DSN（Data Source Name）是Sentry 管理项目需要的PROJECT_ID，每个应用都需要对应一个 PROJECT_ID，以及用于身份认证的 PUBLIC_KEY 和 SECRET_KEY
    dsn: 'https://1324df946d2b42f098351526b9df3d38@sentry-us.didiglobal.com/110',
    // 性能监控开启
    integrations: [new BrowserTracing()],
    // 注意，这里的 release 配置是用来匹配当前项目的 sourcemap 文件的，规则是用项目名 + @ + 项目当前版本号
    // 当项目开启 sourcemap 之后，可通过此参数在错误时直接定位到错误源码
    release: `${process.env.packageJsonName}@${process.env.packageJsonVersion}`,
    // 性能监控相关的数值，控制每个事务都有几个百分比的机会被发送到 Sentry，取 0 到 1 之间的数字，0.3 代表30%
    tracesSampleRate: 0.3,
    environment: process.env.appEnv || 'prod'
  });
};

export default sentryInit;
