export default {
  "Entrega_B2C_settings_payment_methods_changepaymentmethodtoast3": "You have successfully switched to Boleto payments",
  "Entrega_B2C_settings_payment_methods_changepaymentmethodtoast4": "Payment with Boleto is currently unavailable. You can continue to pay for {{99Entrega}} orders with a credit card",
  "Entrega_B2C_settings_payment_methods_changepaymentmethodtoast5": "You can’t switch back to credit card payments now because you have an unpaid bill. You can view your bill on the {{billspage}} page",
  "Entrega_B2C_newdelivery_popupbutton2": "Pay Now",
  "Entrega_B2C_settings_payment_methods_changepaymentmethodtoast1": "You have already changed your payment method once this month. Please try again next month",
  "Entrega_B2C_newdelivery_popupbutton1": "Got It",
  "Entrega_B2C_statement_actions_receipt": "Order Details",
  "Entrega_B2C_settings_payment_methods_changepaymentmethodtoast2": "Payment with Boleto is currently unavailable. Please try again after {{date}}",
  "Entrega_B2C_Accounting_Bills_Orderdetails_refund": "Refunded",
  "Entrega_B2C_cannot_cancel_": "This order cannot be cancelled. Please refresh the order information",
  "Entrega_B2C_new_delivery_dropoff_information_validation": "Please complete the dropoff details",
  "Entrega_B2C_Deliveries_list_ongoing": "Ongoing Orders",
  "Entrega_B2C_Website_modifypassword": "Change Password",
  "Entrega_B2C_statement_AmountDue_fine": "Late fee: {{fine}}",
  "Entrega_B2C_new_delivery_save_the_information_explanation": "Do you want to save the information you entered on this page before you leave?",
  "Entrega_B2C_Homepage_description_delivery_now": "Request a Delivery",
  "Entrega_B2C_Registration_previousstep": "Back",
  "New_Category_Apps_details_yHOm_EWmJ": "Order has been cancelled. Please contact Customer Support for more information",
  "Entrega_B2C_new_delivery_dropoff_information_enter_contact_person_name": "Enter recipient’s name",
  "Entrega_B2C_Website_Portuguese": "Português",
  "Entrega_B2C_statement_AmountDue_interest": "Interest: {{interest}}",
  "Entrega_B2C_Accounting_debitnote": "Debit Notes",
  "Entrega_B2C_Accounting_Bills_list_PayID": "Payment ID",
  "Entrega_B2C_new_delivery_pickup_information_enter_complement": "Enter additional pickup address information",
  "Entrega_B2C_new_delivery_payment_failure_updated_price": "Try Again",
  "Entrega_B2C_Website_slogan": "{{99Entrega}}, deliver whatever you need",
  "Entrega_B2C_Website_EN": "EN",
  "Entrega_B2C_Deliveries_list_Histories": "Completed Orders",
  "Entrega_B2C_statement_actions_boleto": "Boleto",
  "Entrega_B2C_address_limitaion": "Additional address information is too long. Please modify",
  "Entrega_B2C_statement_duedate_updated": "Updated",
  "Entrega_B2C_settings_payment_methods_boleto_apply": "Apply",
  "Entrega_B2C_Deliveries_search_fillinrecepientaddress": "Enter recipient’s address",
  "Entrega_B2C_Accounting_Bills_list_operation": "Actions",
  "Entrega_B2C_statement_download_failed": "Unable to download. Please try again",
  "New_Category_Apps_Companyinfo_BHqW_SwQl": "Email Address",
  "Entrega_B2C_settings_payment_methods_pendingreview": "Pending review",
  "Entrega_B2C_Accounting_debitnote_totalamount": "Total Amount",
  "Entrega_B2C_Homepage_description_newdelivery": "Create an order to start using our delivery service",
  "Entrega_B2C_Accounting_Bills_list_formofpayment": "Payment Method",
  "New_Category_Apps_details_ayEA_eBGp": "The pickup code is proof of handover. Please give this code to the courier after they’ve picked up your package.",
  "Entrega_B2C_Accounting_Bills_list_status": "Payment Status",
  "Entrega_B2C_history_order_cancel_failure": "Unable to cancel order",
  "Entrega_B2C_Accounting_debitnote_numofruns": "Total Orders",
  "Entrega_B2C_Deliveries_list_address": "Pickup / Dropoff Addresses",
  "Entrega_order_history_end_date": "End date",
  "Entrega_B2C_Website_ordernow": "Order Now",
  "Entrega_B2C_statement_AmountDue_newamount": "New amount: {{new}}",
  "Entrega_B2C_settings_payment_methods_boleto_residentiallimit": "Available credit: {{left}}",
  "Entrega_B2C_Registration_estimatedvolume": "Estimated Monthly Delivery Volume",
  "Entrega_B2C_Accounting_Bills_bills": "Bills",
  "New_Category_Apps_details_mQWr_dSmr": "Order Closed",
  "EntregaB2C_settings_developer_testmode_apply_confirm": "Confirm",
  "Entrega_B2C_new_delivery_pickup_information_enter_contact_number_validation": "Please enter a valid phone number for the sender",
  "Entrega_B2C_debit_note_sucess_download": "Bill downloaded successfully",
  "Entrega_B2C_Accounting_Bills_Orderdetails_refundamount": "Refund Amount",
  "Entrega_B2C_transactionhistory": "Payment History",
  "Entrega_B2C_Accounting_Bills_list_status_paid": "Paid",
  "Entrega_B2C_account": "Company Info",
  "Entrega_B2C_bills_view": "View",
  "Entrega_B2C_Deliveries_search_recepientaddress": "Recipient’s Address",
  "Entrega_B2C_statement_billingnumber": "Bill ID",
  "Entrega_B2C_new_delivery_total_price": "Total Amount",
  "New_Category_Apps_details_AWjw_cUlD": "Your courier will arrive at {{time}}. Please have your package ready and phone available",
  "Entrega_B2C_Deliveries_list": "Order List",
  "Entrega_B2C_settings_payment_methods_boleto_totallimit": "Total credit limit: {{total}}",
  "Entrega_B2C_Homepage_description_Bills": "View your full payment history",
  "Entrega_B2C_Homepage_description_details": "View Details",
  "Entrega_B2C_new_delivery_pickup_information_complement": "Additional Pickup Address Information",
  "Entrega_B2C_new_delivery_payment_failure_updated_failed": "Unable to estimate the order price",
  "Entrega_B2C_new_delivery_save_the_information_leave_button": "Discard",
  "Entrega_B2C_Accounting_Bills_Orderdetails_total": "Total {{amount}}",
  "Entrega_B2C_settings_payment_methods_boleto_paymentterm": "Payment period: {{paymentterm}}",
  "Entrega_B2C_new_delivery_dropoff_information_contact_number": "Recipient’s Phone Number",
  "Entrega_B2C_Registration_companyname2": "Please enter company name",
  "Entrega_B2C_Registration_companyname1": "Enter company name",
  "Entrega_B2C_new_delivery_pending_order": "You have an unpaid order",
  "Entrega_B2C_new_delivery_add_pickup_details": "Add pickup details",
  "Entrega_B2C_directory_newdelivery": "New Order",
  "Entrega_B2C_networkbreakdown_toast1": "Action unsuccessful. Please try again later",
  "Entrega_B2C_networkbreakdown_retry": "Try Again",
  "Entrega_B2C_networkbreakdown_toast2": "Network error. Please refresh and try again",
  "Entrega_B2C_Homepage_description_deliveris": "View and manage all of your orders",
  "New_Category_Apps_details_Zoin_QyVr": "Time delivered: {{time}}",
  "Entrega_B2C_Registration_complement1": "e.g., Apartment 101",
  "Entrega_B2C_new_delivery_pickup_information_cancel": "Cancel",
  "New_Category_Apps_details_AbyF_NMKH": "Waiting for Courier to Accept",
  "Entrega_B2C_Registration_risknote": "Sorry, unable to sign up due to an error with your company information",
  "Entrega_B2C_bills_reset": "Reset",
  "Entrega_order_history_status": "Status",
  "Entrega_B2C_directory_payment": "Payment Info",
  "Entrega_B2C_Deliveries_list_cancel": "Cancel",
  "Entrega_B2C_new_delivery_dropoff_information_enter_dropoff_address": "Enter dropoff address",
  "Entrega_B2C_new_delivery_advanced": "Advanced Options",
  "Entrega_B2C_new_delivery_order_cancel_timeout": "One of your orders was cancelled because payment was not made in time",
  "Entrega_B2C_new_delivery_enter_pickup_information_validation": "Please enter the pickup address",
  "Entrega_B2C_Settings_registrationdate_company": "Registration Information – Company Information",
  "Entrega_B2C_Deliveries_search_fillinrecepientphone": "Enter recipient’s mobile number",
  "Entrega_B2C_directory_deliveries": "Order History",
  "Entrega_B2C_networkbreakdown_toast": "System error. Please refresh and try again",
  "Entrega_B2C_new_delivery_pickup_information_contact_number": "Sender’s Phone Number",
  "Entrega_B2C_debit_note_sucess_failure": "Unable to download bill",
  "Entrega_B2C_new_delivery_pickup_time": "Pickup Time",
  "Entrega_B2C_statement_nodata": "No bills found",
  "Entrega_B2C_Registration_companyaddress": "Company Address",
  "Entrega_B2C_Deliveries_search_fillinrecepientname": "Enter recipient’s name",
  "New_Category_Apps_details_gfOJ_AqBe": "The delivery code is proof of handover. The recipient gives this code to the courier after accepting your package.",
  "Entrega_B2C_new_delivery_dropoff_information_complement": "Additional Dropoff Address Information",
  "Entrega_B2C_Registration_zipcode": "Post Code",
  "Entrega_B2C_Registration_complement": "Additional Address Line",
  "Entrega_B2C_new_delivery_pickup_code_details": "Please inform the courier of the 4-digit pickup code when they arrive for pickup",
  "Entrega_B2C_new_delivery_pickup_information_service_validation": "The address you entered is not in your selected city. Please try another address",
  "Entrega_B2C_Deliveries_list_price": "Price",
  "Entrega_B2C_settings_payment_methods_changetoboletopopup_title": "Switch to Boleto Payments",
  "Entrega_B2C_newdelivery_popuptitle1": "Insufficient Credit",
  "Entrega_B2C_newdelivery_popuptitle2": "Overdue Bill",
  "Entrega_B2C_Accounting_Bills_list_Payday": "Payment Time",
  "New_Category_Apps_details_KSQo_BLSm": "Delivery Code",
  "Entrega_B2C_Website_signup": "Sign Up",
  "Entrega_B2C_new_delivery_dropoff_information_choose_package_weight": "Please choose package weight",
  "New_Category_Apps_details_EQLy_QWsI": "Order ID",
  "Entrega_B2C_Registration_city2": "Please select a city",
  "New_Category_Apps_details_tUHk_DCfd": "Your courier has arrived. Please provide your package to the courier as soon as possible",
  "Entrega_B2C_Registration_city1": "Select a city",
  "Entrega_B2C_Registration_operationinfo": "Business Information",
  "Entrega_B2C_new_delivery_confirm_modification_city_explanation": "Once the city is changed, the current information will be cleared. Are you sure you want to continue?",
  "Entrega_B2C_Deliveries_list_emptylist": "No orders yet",
  "Entrega_B2C_Accounting_Bills_list_refundamount": "Refund Amount",
  "Entrega_B2C_Registration_failure3": "View Details",
  "Entrega_B2C_Deliveries_search_status": "Select status",
  "Entrega_B2C_Registration_failure1": "Your company information could not be verified",
  "Entrega_B2C_Registration_failure2": "Click the button below to view more details. You can modify your company information and submit another request for verification.",
  "Entrega_B2C_history_order_code": "Verification Code",
  "Entrega_B2C_bills_search": "Search",
  "Entrega_B2C_Registration_companyname": "Company Name",
  "Entrega_B2C_Accounting_Bills_Orderdetails": "Payment Details",
  "Entrega_B2C__settings_payment_methods_managemycard": "Manage",
  "Entrega_B2C_new_delivery_maximum_10_dropoff": "Up to 10 Dropoff Addresses",
  "Entrega_B2C_Website_Portuguese1": "PT",
  "Entrega_B2C_settings_payment_methods_boleto_billingcycle_tip": "Bills are generated on the first business day of each month",
  "Entrega_B2C_statement_duedate": "Due Date",
  "Entrega_B2C_new_delivery_pending_order_explanation": "Please pay for the unpaid order first before placing new orders",
  "Entrega_B2C_new_delivery_order_now": "Order Now",
  "Entrega_B2C_new_delivery_add_dropoff_information": "Add dropoff details",
  "Entrega_B2C_Accounting_Bills_Orderdetails_refundtime": "Refund Time",
  "Entrega_B2C_new_delivery_pickup_address": "Pickup Address",
  "Entrega_B2C_settings_payment_methods_selectpaymentmethod": "Please select a payment method",
  "New_Category_Apps_details_ajoT_FBnL": "Package Delivered",
  "Entrega_B2C_new_delivery_dropoff_information_category": "Package Type",
  "Entrega_B2C_Accounting_Bills_Orderdetails_moredetails": "More details",
  "Entrega_order_history_start_date": "Start date",
  "Entrega_B2C_new_delivery_pickup_information_validation": "Please complete the pickup details",
  "Entrega_B2C_debitnote_paymentmethod": "Payment Method",
  "Entrega_B2C_new_delivery_save_the_information": "Save Information",
  "Entrega_B2C_new_delivery_cancelwording": "Please tell us why you want to cancel your order, and we’ll do our best to improve your experience. If the Delivery Fee was paid by credit card, the refund will be credited to your payment account within 7 to 15 business days",
  "New_Category_Apps_details_HRjt_Qzfv": "Order has been closed. Please contact Customer Support for more information",
  "Entrega_B2C_Accounting_Bills_Orderdetails_totalamount": "Total Amount",
  "Entrega_B2C_new_delivery_pickup_information_enter_Notes": "Enter notes here, like the size of the item (if the item is bulky) or other info the courier needs to know during pickup. This info will be shared with the courier. Please don’t enter confidential or proprietary information.",
  "Entrega_B2C_new_delivery_pickup_information_enter_contact_person_name_validation": "Please enter sender’s name",
  "New_Category_Apps_details_qIYs_kBzP": "Please wait a moment while we find a courier for you",
  "Entrega_B2C_newdelivery_insufficientresidualcredit": "Insufficient available credit: {{residual_credit}}",
  "Entrega_B2C_new_delivery_confirm_modification_city_modify_button": "Change",
  "Entrega_B2C_Registration_number": "Street Number",
  "New_Category_Apps_details_PfKj_ULAj": "Delivery in Progress",
  "Entrega_B2C_Accounting_Bills_list_pay": "Pay",
  "Entrega_B2C_Registration_zipcode2": "Please enter post code in this format: XXXXX-XXX",
  "Entrega_B2C_Registration_zipcode1": "Enter post code in this format: XXXXX-XXX",
  "Entrega_B2C_new_delivery_dropoff_information_enter_contact_number": "Enter recipient’s phone number",
  "New_Category_Apps_details_UDqE_blno": "Address Details",
  "Entrega_B2C_new_delivery_save_the_information_save_button": "Save",
  "Entrega_B2C_new_delivery_confirm_modification_city": "Confirm City Change",
  "Entrega_B2C_Registration_zipcode3": "Invalid zip code format. Please check and try again",
  "Entrega_B2C_Deliveries_search_recepientphone": "Recipient’s Mobile Number",
  "Entrega_B2C_Registration_submittip3": "We’ll email your onboarding results to you within 1–3 business days. Thank you!",
  "Entrega_B2C_Registration_submittip2": "We look forward to working with you and helping you achieve your delivery goals",
  "Entrega_B2C_Registration_submittip1": "Submitted successfully",
  "New_Category_Apps_orders_XPKr_IJRz": "Package Info",
  "Entrega_B2C_new_delivery_dropoff_information_enter_Notes": "Enter notes here, like the size of the item (if the item is bulky) or other info the courier needs to know during dropoff. This info will be shared with the courier. Please don’t enter confidential or proprietary information.",
  "Entrega_B2C_new_delivery_dropoff_information_enter_dropoff_address_validation": "Please enter the dropoff address",
  "New_Category_Apps_details_lggF_KuLf": "Package Info",
  "Entrega_B2C_Website_99Entrega": "99Entrega",
  "Entrega_B2C_Registration_city": "City",
  "Entrega_B2C_statement_AmountDue": "Amount Payable",
  "Entrega_B2C_Website_introduction": "Enjoy fast, safe and convenient delivery services",
  "Entrega_B2C_Accounting_Bills_list_status_tobepaid": "Unpaid",
  "Entrega_B2C_statement_AmountDue_originalamount": "Original amount: {{old}}",
  "Entrega_B2C_history_order_cancel_success": "Order cancelled successfully",
  "Entrega_B2C_Deliveries_search_recepientname": "Recipient’s Name",
  "Entrega_B2C_Accounting_Bills_Orderdetails_priceinformation": "Pricing Details",
  "Entrega_B2C_new_delivery_code_details": "Please inform the recipient of the 4-digit delivery code so they can present it to the courier upon arrival",
  "Entrega_B2C_Deliveries_list_pickuptime": "Pickup Time",
  "Entrega_B2C_new_delivery_delivery_code": "Delivery Code",
  "Entrega_B2C_Registration_startusing5": "Specialized couriers ensure that your merchandise is delivered safely, with real-time location tracking during the delivery process",
  "Entrega_B2C_new_delivery_dropoff_information_package_weight": "Package Weight",
  "Entrega_B2C_Registration_basicinfo": "Company Information",
  "New_Category_Apps_details_cprL_wHtG": "Courier Details",
  "Entrega_B2C_bills_date": "Date Range",
  "Entrega_B2C_new_delivery_pending_order_to_pay_button": "Pay Now",
  "Entrega_B2C_new_delivery_dropoff_information_enter_contact_person_name_validation": "Please enter a valid recipient name",
  "Entrega_B2C_statement_enterbillingnumber": "Enter Bill ID",
  "Entrega_B2C_Accounting_Bills_Orderdetails_orderID": "Order ID",
  "Entrega_B2C_new_delivery_dropoff_information": "Dropoff Details",
  "New_Category_Apps_details_FqMP_biOY": "Cancel Order",
  "Entrega_B2C_settings_payment_methods_changepaymentmethodpopup_content": "Are you sure you want to apply for Boleto payments? We’ll assess your eligibility within 3 business days and notify you of the result as soon as possible.",
  "Entrega_B2C_Registration_startusing4": "Transparent pricing, distance-based fees, and a pay-per-delivery option",
  "Entrega_B2C_Registration_startusing3": "Arrange deliveries for orders from your company’s website, phone line, and app",
  "Entrega_B2C_Settings_registrationdate_mainuser_email": "Phone Number",
  "Entrega_B2C_Registration_startusing2": "Sign up to provide on-demand deliveries to your customers",
  "Entrega_B2C_new_delivery_cancel_button1": "Cancel Order",
  "Entrega_B2C_Registration_startusing1": "Start Using {{99Entrega}}",
  "Entrega_B2C_Homepage_description_settings": "Manage system settings",
  "Entrega_B2C_statement_status": "Bill Status",
  "Entrega_B2C_new_delivery_dropoff_information_contact_person_name": "Recipient’s Name",
  "Entrega_B2C_new_delivery_cancel_button3": "Go Back",
  "New_Category_Apps_details_YLwR_BPBA": "Pickup Code",
  "Entrega_B2C_new_delivery_dropoff_information_dropoff_address": "Dropoff Address",
  "Entrega_B2C_new_delivery_cancel_button2": "Go Back",
  "Entrega_B2C_Accounting_debitnote_period": "Billing Period",
  "Entrega_B2C_Registration_submit": "Submit",
  "Entrega_B2C_new_delivery_pickup_information_enter_contact_person_name": "Enter sender’s name",
  "Entrega_B2C_new_delivery_cancel_button4": "Cancel Order",
  "Entrega_B2C_Homepage_description_Debitnote": "Download your debit notes",
  "Entrega_B2C_settings_payment_methods_changetocreditcardpopup_title": "Switch to Credit Card Payments",
  "Entrega_B2C_Settings_registrationdate_company_reject": "Onboarding unsuccessful. Reason: {{reject_reason}}",
  "EntregaB2C_settings_developer_onlinemode_cancel": "Cancel",
  "Entrega_B2C_Accounting_Bills_Orderdetails_orderinformation": "Order Details",
  "Entrega_B2C_new_delivery_dropoff_information_enter_contact_number_validation": "Please enter a valid recipient phone number",
  "Entrega_B2C_statement_duedate_originaldate": "Original date: {{old}}",
  "Entrega_B2C_new_delivery_add_dropoff": "Add Another Dropoff Address",
  "Entrega_B2C_Registration_CNPJ": "Tax ID",
  "New_Category_Apps_details_kOXX_JIxu": "Waiting for Courier to Accept",
  "Entrega_B2C_Accounting_Bills_list_status_cancelled": "Cancelled",
  "Entrega_B2C_Accounting_Bills_list_cancelsuccess": "Order cancelled successfully. Please refresh",
  "Entrega_B2C_company_name_limitaion": "Company name is too long. Please modify",
  "Entrega_B2C_settings_payment_methods_selectpaymentmethod_tip": "You can only change your payment method once a month",
  "Entrega_B2C_new_delivery_pickup_information_contact_person_name": "Sender’s Name",
  "Entrega_B2C_new_delivery_confirm_modification_city_cancel_button": "Cancel",
  "Entrega_B2C_new_delivery_pickup_information_save": "Save",
  "Entrega_B2C_new_delivery_pickup_code": "Pickup Code",
  "Entrega_B2C_Registration_nextstep": "Next",
  "Entrega_B2C_Registration_estimatedvolume1": "Select estimated monthly delivery volume",
  "Entrega_B2C_Registration_estimatedvolume2": "Please select estimated monthly delivery volume",
  "Entrega_B2C_directory_setting": "Settings",
  "Entrega_B2C_settings_payment_methods_boleto_billingcycle": "Billing period: {{Billingcycle}}",
  "Entrega_B2C_statement_AmountPaid": "Amount Paid",
  "Entrega_B2C_newdelivery_residualcredit": "Available credit: {{residual_credit}}",
  "Entrega_B2C_Deliveries_list_orderagain": "Order Again",
  "Entrega_B2C_Registration_number1": "Enter the street number",
  "Entrega_B2C_Registration_number2": "Please enter the street number",
  "Entrega_B2C_new_delivery_pickup_time_now": "Now",
  "Entrega_B2C_Website_login": "Sign In",
  "Entrega_B2C_Website_logout": "Sign Out",
  "Entrega_B2C_statement_bubble": "If you have any questions about a bill, click here to contact Customer Support",
  "Entrega_B2C_Registration_CNPJ4": "Invalid tax ID format. Please check and try again",
  "Entrega_B2C_Registration_businesstype1": "Select a business type",
  "Entrega_B2C_Registration_businesstype2": "Please select a business type",
  "Entrega_B2C_Registration_CNPJ2": "Please enter Tax ID",
  "Entrega_B2C_Registration_CNPJ1": "Enter Tax ID in this format: XX.XXX.XXX/XXXX-XX",
  "Entrega_B2C_new_delivery_payment_failure_updated_failed_explanation": "Unable to estimate the order price. Please click the button to try again",
  "Entrega_B2C_directory_home": "Home",
  "Entrega_B2C_Deliveries_detail_ordercreationtime": "Order Creation Time",
  "Entrega_B2C_Website_English": "English",
  "New_Category_Apps_details_VmiH_KcYh": "Estimated delivery time: {{time}}",
  "New_Category_Apps_details_BpIB_HITO": "Courier availability is currently limited. Please wait a moment",
  "Entrega_B2C_new_delivery_dropoff_information_enter_complement": "Enter additional dropoff address information",
  "Entrega_B2C__settings_payment_methods_creditcard": "Credit Card",
  "Entrega_B2C_statement": "Bills",
  "Entrega_B2C_Settings_registrationdate_mainuser": "Registration Information – User Information",
  "Entrega_B2C_debitnote_selectpaymentmethod": "Select a payment method",
  "Entrega_B2C_settings_payment_methods_changetocreditcardpopup_content": "If you switch to credit card payments and want to use Boleto payments again later, you will need to reapply. Continue?",
  "Entrega_B2C_Registration_companyaddress2": "Please enter your company’s address",
  "Entrega_B2C_new_delivery_vehicle_types": "Vehicle Type",
  "Entrega_B2C_directory_payment_methods": "Payment Settings",
  "Entrega_B2C_Accounting_Bills_list_amountpaid": "Payment Amount",
  "New_Category_Apps_details_cSAS_LrRJ": "Order Details",
  "Entrega_B2C_new_delivery_enter_pickup_information": "Enter the pickup address",
  "Entrega_B2C_Registration_companyaddress1": "Enter your company’s address",
  "Entrega_B2C_Registration_businesstype": "Business Type",
  "Entrega_B2C_Accounting_debitnote_nodebitnote": "No debit notes available",
  "Entrega_B2C_new_delivery_pickup_information_Notes": "Notes",
  "Entrega_B2C_new_delivery_pickup_information": "Pickup Details",
  "Entrega_B2C_statement_duedate_newdate": "New date: {{new}}",
  "Entrega_B2C_Accounting_Bills_Orderdetails_createtime": "Order creation time: {{yyyymmddhh}}",
  "Entrega_B2C_statement_download_success": "Downloaded successfully",
  "Entrega_B2C_new_delivery_dropoff_information_category_validation": "Please select an item type",
  "Entrega_B2C_newdelivery_popupcontent1": "This order can’t be submitted because your account doesn’t have enough available credit to pay the Delivery Fee",
  "Entrega_B2C_newdelivery_popupcontent2": "You cannot use delivery services at this time because you have an overdue bill. Please pay your bill, then try again. For any billing related questions, please contact Customer Support.",
  "Entrega_B2C_Accounting_Bills_list_nobills": "No bills available",
  "Entrega_B2C_new_delivery_cancel_title1": "Cancel Order",
  "Entrega_B2C_Deliveries_list_status_accepted": "Accepted",
  "Entrega_B2C_new_delivery_cancel_title2": "Are you sure you want to cancel this order?",
  "Entrega_B2C_statement_selectstatus": "Select a bill status",
  "Entrega_B2C_new_delivery_order_information": "Order Information",
  "Entrega_B2C_delivery_account_block": "Due to an issue with this account, orders cannot be requested at this time",
  "Entrega_B2C_notes_limitaion": "Note is too long. Please modify",
  "New_Category_Apps_details_VZqW_ZPZb": "Order Cancelled",
  "Entrega_B2C_Accounting_Bills_accounting": "Financial Management",
  "Entrega_B2C_Accounting_Bills_Orderdetails_paytime": "Actual payment time: {{yyyymmddhh}}",
  "Entrega_B2C_new_delivery_cancelwording2": "If the Delivery Fee was paid by credit card, the refund will be credited to your payment account within 7 to 15 business days",
  "Entrega_B2C_settings_payment_methods_boleto": "Boleto",
  "Entrega_B2C_toast2": "No data available",
  "Entrega_B2C_toast1": "Unable to get data. Please try again",
  "Entrega_B2C_Accounting_debitnote_download": "Download",
  "Entrega_B2C_new_delivery_pickup_information_enter_contact_number": "Enter sender’s phone number",
  "Entrega_B2C_statement_actions_debitnote": "Debit Note",
  "Entrega_B2C_Deliveries_list_orderID": "Order ID"
}