import { lazy } from 'react';

import { SyncRouteObject } from '../types';

const Page = lazy(() => import('@/components/layouts/page'));
const RootPage = lazy(() => import('@/pages/root'));
const NewCompanyPage = lazy(() => import('@/pages/newCompany'));
const HomePage = lazy(() => import('@/pages/home'));
const NewDeliverPage = lazy(() => import('@/pages/newDeliver'));
const DeliversPage = lazy(() => import('@/pages/delivers'));
const SettingPage = lazy(() => import('@/pages/setting'));
const BillsPage = lazy(() => import('@/pages/bills'));
const StatementPage = lazy(() => import('@/pages/statement'));
const DebitNotePage = lazy(() => import('@/pages/debitNote'));
const PaymentPage = lazy(() => import('@/pages/payment'));

const ROUTER_CONFIG: SyncRouteObject[] = [
  {
    path: '/',
    element: RootPage
  },
  {
    path: '/newCompany',
    element: NewCompanyPage
  },
  {
    path: '/',
    element: Page,
    children: [
      {
        path: '/home',
        element: HomePage
      },
      {
        path: '/newDeliver',
        element: NewDeliverPage
      },
      {
        path: '/delivers',
        element: DeliversPage
      },
      {
        path: '/accounting-bills',
        element: BillsPage
      },
      {
        path: '/accounting-statement',
        element: StatementPage
      },
      {
        path: '/accounting-debitNote',
        element: DebitNotePage
      },
      {
        path: '/setting-company',
        element: SettingPage
      },
      // 入口 boleto 转移
      // {
      //   path: '/setting-bindCard',
      //   element: BindCardPage
      // },
      {
        path: '/setting-payment',
        element: PaymentPage
      },
      {
        path: '*',
        element: HomePage
      }
    ]
  }
];

export default ROUTER_CONFIG;
