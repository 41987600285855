import { Popover } from '@didi-pebble/pebble-design-react';
import { useAtom } from 'jotai';

import { $t } from '@/lib/i18n';
import { userInfoAtom } from '@/store/common';
import { getPassportUrl } from '@/lib/passport';

const AvatarComp: React.FC = () => {
  const [userInfoValue] = useAtom(userInfoAtom);

  return (
    <div className='w-full h-full flex items-center'>
      <Popover
        trigger='click'
        overlayInnerStyle={{ padding: '0px' }}
        placement='bottomRight'
        content={() => {
          return (
            <div className='w-[200px] pb-1'>
              <div className='flex flex-col items-center mb-2 px-5 pt-5'>
                <div className='img_icon icon_avatarMid'></div>
                <div className='mt-2 text-sm leading-5 text-[#282B33] font-DiDiSansPro-Medium font-medium'>
                  {userInfoValue?.phone}
                </div>
              </div>
              <div
                className='w-full px-3 py-[10px] font-DiDiSansPro-Regular text-sm leading-5 text-[#595C66] cursor-pointer hover:bg-[#FFF7F2] hover:text-[#F76A31] hover:font-medium hover:font-DiDiSansPro-Medium'
                onClick={() => {
                  window.location.href = getPassportUrl().forget;
                }}
              >
                {$t('Entrega_B2C_Website_modifypassword') || '修改密码'}
              </div>
              <div
                className='w-full px-3 py-[10px] font-DiDiSansPro-Regular text-sm leading-5 text-[#595C66] cursor-pointer hover:bg-[#FFF7F2] hover:text-[#F76A31] hover:font-medium hover:font-DiDiSansPro-Medium'
                onClick={() => {
                  window.location.href = getPassportUrl().logout;
                }}
              >
                {$t('Entrega_B2C_Website_logout') || '登出'}
              </div>
            </div>
          );
        }}
      >
        <div className='flex items-center'>
          <div className='img_icon icon_avatar'></div>
        </div>
      </Popover>
    </div>
  );
};

export default AvatarComp;
