import { JsonParse } from '@/utils';

const PASSPORT_PAGE = 'https://page.didiglobal.com/public-biz/pc-login/3.1.2/index.html';
const appid = 200067;
const role = 5010;
const source = 70001;
const lang = JsonParse(localStorage.getItem('commonLang'))?.lang?.value || 'pt-BR';
const countryId = 76;

export const getPassportUrl = () => {
  const passportOrigin = window.location.origin?.includes('sim')
    ? 'https://dsim000-api.intra.xiaojukeji.com'
    : window.location.origin;
  const REDIRECT_URL = encodeURIComponent(
    `${passportOrigin}${process.env.routerBaseName}/api/v1/login?jumpto_web=${window.location.href}`
  );
  const passportBaseUrl = `${PASSPORT_PAGE}?appid=${appid}&role=${role}&source=${source}&lang=${lang}&country_id=${countryId}&theme=yellow&redirectUrl=${REDIRECT_URL}`;
  const PASSPORT = {
    login: passportBaseUrl,
    logout: `${passportBaseUrl}#/logout`,
    register: `${passportBaseUrl}#/register`,
    forget: `${passportBaseUrl}#/forget`
  };

  return PASSPORT;
};

export default getPassportUrl;
