import OmegaTracker from '@didi/omega-tracker-lite';

import { JsonParse } from '@/utils';
import { InfoRes } from '@/api/common.type';

// 事件的属性
export interface IAttrs {
  [k: string]: any;
}

const config = {
  appKey: 'omegac47931dc6d', // appkey
  uploadHost: localStorage.getItem('omegaUploadHost') || process.env.omegaUploadHost || '',
  autoClick: false // 全局点击事件监控，如不需要 建议关闭
};

const omegaInstance: any = OmegaTracker.getTracker(config);

export class Omega {
  static getBasicAttrs() {
    const commonCity = JsonParse(localStorage.getItem('commonCity') as string);
    const commonLang = JsonParse(localStorage.getItem('commonLang') as string);
    const userInfo: InfoRes = JsonParse(localStorage.getItem('userInfo') as string);
    const entry = sessionStorage.getItem('entry');
    const lat = localStorage.getItem('user_lat') || '';
    const lng = localStorage.getItem('user_lng') || '';

    return {
      b_id: userInfo?.companies?.[0]?.id || '',
      city_id: commonCity?.city?.city_id,
      country: commonCity?.country?.country_code,
      lang: commonLang?.lang?.value,
      entry,
      lat,
      lng
    };
  }

  /**
   * 初始化
   */
  static init() {
    const commonCity = JsonParse(localStorage.getItem('commonCity') as string);

    omegaInstance.setConfig({
      countryCode: commonCity?.country?.country_code || ''
    });
  }

  /**
   * 事件埋点
   */
  static trackEvent(eventId: string, attrs = {}) {
    try {
      const basicAttrs = Omega.getBasicAttrs() || {};

      omegaInstance.trackEvent(eventId, '', { ...basicAttrs, ...attrs });
    } catch (e) {
      console.log('omega upload error');
    }
  }

  /**
   * 通用接口异常埋点
   * @param {*} base
   * @param {*} param1
   */
  static trackApiError({
    base = '',
    path = '',
    params = '',
    status = '',
    code = '',
    msg = '',
    traceId = ''
  }: {
    base?: string;
    path?: string;
    params?: any;
    status?: number | string;
    code?: number | string;
    msg?: string;
    traceId?: string;
  }) {
    try {
      const omegaParams = {
        base: base || path,
        path,
        params,
        status,
        code,
        msg,
        trace_id: traceId
      };

      Omega.trackEvent('tech_entrega_global_api_error', omegaParams);
    } catch (err) {
      console.log('omega上报错误');
    }
  }

  /**
   * 通用业务埋点
   * @param {*} eventId
   * @param {*} param1
   */
  static trackBusiness(eventId: any, { business, type, status, msg, params }: any) {
    try {
      const omegaParams = {
        business,
        type,
        status,
        msg,
        params
      };

      Omega.trackEvent(eventId, omegaParams);
    } catch (err) {
      console.log('omega上报错误');
    }
  }
}

export default Omega;
