export enum ReviewStatusDic {
  newRegist = 0, // 新注册
  reviewing = 1, // 待审核
  reviewFail = 2, // 审核失败
  reviewSuccess = 3, // 审核成功
  overdue = 4, // 欠款
  disabled = 5 // 已禁用，账号有风险
}

export default ReviewStatusDic;
