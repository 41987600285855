import { Button } from '@didi-pebble/pebble-design-react';

import { $t } from '@/lib/i18n';

interface SystemErrorProps {
  text?: string;
  onClick?: any;
}

export const SystemError = (props: SystemErrorProps) => {
  const { text, onClick } = props;

  return (
    <div className='system_error h-full w-full flex items-center justify-center'>
      <div className='w-full flex flex-col items-center'>
        <div className='img_icon icon_apiError'></div>
        <div className='mt-5 text-base text-[#282B33] font-DiDiSansPro-Regular'>
          {text || $t('Entrega_B2C_networkbreakdown_toast') || '系统异常，请刷新重试'}
        </div>
        {onClick && (
          <div className='mt-6'>
            <Button type='primary' onClick={onClick}>
              {$t('Entrega_B2C_networkbreakdown_retry') || '重试'}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SystemError;
