import { $t } from '@/lib/i18n';
import { ReactComponent as HomeIcon } from '@/assets/images/svg/home.svg';
import { ReactComponent as DocIcon } from '@/assets/images/svg/doc.svg';
import { ReactComponent as DatabaseIcon } from '@/assets/images/svg/database.svg';
import { ReactComponent as SettingIcon } from '@/assets/images/svg/setting.svg';
/**
 * 当前页面的配置、字典、常量(如枚举)
 */
export enum MenuDict {
  Root = 'Root',
  Home = 'Home',
  Dlivers = 'Dlivers',
  Accounting = 'Accounting',
  Bills = 'Bills',
  DebitNote = 'DebitNote',
  Setting = 'Setting',
  NewDeliver = 'NewDeliver',
  NewCompany = 'NewCompany',
  SettingCompany = 'SettingCompany',
  Payment = 'Payment',
  Statement = 'Statement'
}
export const MenuConfig: {
  [key in MenuDict]: {
    key: MenuDict;
    label: string;
    icon?: React.ReactNode;
    iconHome?: string;
    homeOmegaType?: string;
    iconHomeText?: string;
    routePath: string;
    desc?: string;
  };
} = {
  [MenuDict.Root]: {
    key: MenuDict.Root,
    label: 'Root',
    routePath: '/'
  },
  [MenuDict.Home]: {
    key: MenuDict.Home,
    label: $t('Entrega_B2C_directory_home') || '主页',
    icon: <HomeIcon />,
    routePath: '/home'
  },
  [MenuDict.NewCompany]: {
    key: MenuDict.NewCompany,
    label: 'NewCompany',
    routePath: '/newCompany'
  },
  [MenuDict.NewDeliver]: {
    key: MenuDict.NewDeliver,
    label: $t('Entrega_B2C_directory_newdelivery') || '创建订单',
    desc:
      $t('Entrega_B2C_Homepage_description_newdelivery') || '创建订单，开始使用我们的配送服务吧',
    icon: <DocIcon />,
    iconHome: 'icon_to_newDeliver',
    homeOmegaType: 'new_delivery',
    iconHomeText: $t('Entrega_B2C_Homepage_description_delivery_now') || '去发单',
    routePath: '/newDeliver'
  },
  [MenuDict.Dlivers]: {
    key: MenuDict.Dlivers,
    label: $t('Entrega_B2C_directory_deliveries') || '历史订单',
    desc: $t('Entrega_B2C_Homepage_description_deliveris') || '查看和管理你的全部订单',
    icon: <DocIcon />,
    iconHome: 'icon_to_dlivers',
    iconHomeText: $t('Entrega_B2C_Homepage_description_details') || '查看详情',
    homeOmegaType: 'deliveries',
    routePath: '/delivers'
  },
  [MenuDict.Accounting]: {
    key: MenuDict.Accounting,
    label: $t('Entrega_B2C_Accounting_Bills_accounting') || '财务管理',
    icon: <DatabaseIcon />,
    routePath: '/accounting'
  },
  [MenuDict.Bills]: {
    key: MenuDict.Bills,
    label: $t('Entrega_B2C_transactionhistory') || '账单',
    desc: $t('Entrega_B2C_Homepage_description_Bills') || '查看和管理你的全部账单',
    iconHome: 'icon_to_bills',
    homeOmegaType: 'bills',
    iconHomeText: $t('Entrega_B2C_Homepage_description_details') || '查看详情',
    routePath: '/accounting-bills'
  },
  [MenuDict.Statement]: {
    key: MenuDict.Statement,
    label: $t('Entrega_B2C_statement') || '对账单',
    desc: $t('Entrega_B2C_Homepage_description_Bills') || '查看和管理你的全部账单',
    iconHome: 'icon_to_bills',
    homeOmegaType: 'statement',
    iconHomeText: $t('Entrega_B2C_Homepage_description_details') || '查看详情',
    routePath: '/accounting-statement'
  },
  [MenuDict.DebitNote]: {
    key: MenuDict.DebitNote,
    label: $t('Entrega_B2C_Accounting_debitnote') || '借记单',
    desc: $t('Entrega_B2C_Homepage_description_Debitnote') || '下载你的借记单',
    iconHome: 'icon_to_debitNote',
    homeOmegaType: 'debit_note',
    iconHomeText: $t('Entrega_B2C_Homepage_description_details') || '查看详情',
    routePath: '/accounting-debitNote'
  },
  [MenuDict.Setting]: {
    key: MenuDict.Setting,
    label: $t('Entrega_B2C_directory_setting') || '设置',
    icon: <SettingIcon />,
    routePath: '/setting'
  },
  [MenuDict.SettingCompany]: {
    key: MenuDict.SettingCompany,
    label: $t('Entrega_B2C_account') || '公司设置',
    desc: $t('Entrega_B2C_Homepage_description_settings') || '管理你的系统设置',
    iconHome: 'icon_to_settingCompany',
    homeOmegaType: 'settings',
    iconHomeText: $t('Entrega_B2C_Homepage_description_details') || '查看详情',
    routePath: '/setting-company'
  },
  [MenuDict.Payment]: {
    key: MenuDict.Payment,
    label: $t('Entrega_B2C_directory_payment_methods') || '支付方式',
    routePath: '/setting-payment',
    homeOmegaType: 'settings-payment'
  }
};

export default {};
