import { Badge, Drawer, Popconfirm, Spin } from '@didi-pebble/pebble-design-react';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useLocation } from 'react-router-dom';

import { SystemError } from '@/components/systemError';
import { usePolling } from '@/hooks';
import { userInfoAtom } from '@/store/common';
import { CommonApiModule } from '@/api/common.api';
import { Omega } from '@/lib';
import { $t } from '@/lib/i18n';

import './index.less';
import { MenuConfig, MenuDict } from '../page/config';

const CsComp: React.FC = () => {
  const location = useLocation();
  const [userInfoValue] = useAtom(userInfoAtom);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [popOpen, setPopOpen] = useState<boolean>(true);
  const [url, setUrl] = useState<string>('');
  const [msgCount, setMsgCount] = useState<number>(0);

  const getCsMsg = async () => {
    try {
      const res = await CommonApiModule.getCsMsg({ uid: userInfoValue?.uid });

      setMsgCount(res?.data?.csMsgCount);
      return {
        hasError: false,
        apiRes: res
      };
    } catch (e) {
      return {
        hasError: true
      };
    }
  };

  const { run, cancel } = usePolling({
    func: getCsMsg,
    intervalFe: 10000
  });
  const getCsConfig = async () => {
    try {
      setLoading(true);
      const { data } = await CommonApiModule.getConfig();

      setUrl(data?.url);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userInfoValue?.uid) {
      run();
    }
  }, [userInfoValue?.uid]);

  useEffect(() => {
    getCsConfig();
    return () => {
      cancel();
    };
  }, []);

  const getOpen = () => {
    return (
      !localStorage.getItem('hasConfirmCsIcon') &&
      location.pathname === MenuConfig[MenuDict.Statement].routePath
    );
  };

  return (
    <div className='w-full h-full flex items-center'>
      <div className='flex relative'>
        <Popconfirm
          title=''
          icon={null}
          placement='bottomRight'
          description={
            <div className='max-w-[240px] text-[14px] text-[#595C66] leading-5'>
              {$t('Entrega_B2C_statement_bubble') || '如您对账单有疑问，可点击此处联系客服'}
            </div>
          }
          okText={$t('Entrega_B2C_newdelivery_popupbutton1') || '知道了'}
          onConfirm={() => {
            setPopOpen(false);
            localStorage.setItem('hasConfirmCsIcon', 'true');
          }}
          showCancel={false}
          open={getOpen() && popOpen}
        >
          <div className='pr-[14px]'>
            <div
              className={`img_icon ${
                location.pathname === MenuConfig[MenuDict.Root].routePath
                  ? 'icon_cs'
                  : 'icon_cs_dark'
              }`}
              onClick={() => {
                Omega.trackEvent('ibt_entrega_b_navigation_service_ck');
                setOpen(true);
              }}
            ></div>
          </div>
        </Popconfirm>

        {msgCount > 0 && (
          <Badge count={msgCount} overflowCount={99} size='small'>
            <div className='w-4 h-4'></div>
          </Badge>
        )}
      </div>

      <Drawer
        className='drawerCs'
        width={446}
        placement='right'
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      >
        <div
          className='img_icon icon_close absolute right-5 top-4'
          onClick={() => {
            setOpen(false);
          }}
        ></div>
        {loading ? (
          <div className='h-full flex items-center justify-center'>
            <Spin spinning={loading}></Spin>
          </div>
        ) : (
          <div className='h-full'>
            {url ? (
              <iframe className='w-full h-full' src={url}></iframe>
            ) : (
              <div className='w-full h-full'>
                <SystemError onClick={getCsConfig}></SystemError>
              </div>
            )}
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default CsComp;
