/**
 * object转string
 */
export const JsonStringify = (value: Object) => {
  try {
    return JSON.stringify(value);
  } catch {
    return '';
  }
};
/**
 * string转object
 */
export const JsonParse = (str: any) => {
  try {
    return JSON.parse(str);
  } catch {
    return null;
  }
};

/**
 * 生成随机36位唯一key
 */
export function generateUniqueKey() {
  const timestamp = new Date().getTime().toString(36);
  const randomStr = Math.random().toString(36).substr(2, 10);
  let key = timestamp + randomStr;

  while (key.length < 36) {
    key += Math.random().toString(36).substr(2, 10);
  }
  return key.substr(0, 36);
}

export function delay(ms: number) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

/**
 * 获取用户经纬度
 */
export function geoFindMe() {
  function success(position: any) {
    const { latitude } = position.coords;
    const { longitude } = position.coords;

    localStorage.setItem('user_lat', latitude);
    localStorage.setItem('user_lng', longitude);
  }

  function error() {}
  if (!navigator.geolocation) {
    console.log('浏览器不支持获取地址');
  } else {
    navigator.geolocation.getCurrentPosition(success, error);
  }
}
