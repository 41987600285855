import { Popover } from '@didi-pebble/pebble-design-react';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { JsonParse, JsonStringify, delay } from '@/utils';
import { LangOptionType, langOptionsAtom } from '@/store/common';
import { $t } from '@/lib/i18n';
import { Omega } from '@/lib';
import { SessionType } from '@/configs';

import { MenuConfig, MenuDict } from '../page/config';

const LangSelect: React.FC = () => {
  const location = useLocation();
  const commonLang = JsonParse(localStorage.getItem('commonLang') as string);
  const [langOptionsValue] = useAtom(langOptionsAtom);
  const [curLang, setCurLang] = useState<any>(commonLang?.lang);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!commonLang) {
      const selectLang = {
        value: 'pt-BR',
        label: $t('Entrega_B2C_Website_Portuguese') || 'Português',
        name: $t('Entrega_B2C_Website_Portuguese1') || 'BR'
      };

      localStorage.setItem(
        'commonLang',
        JsonStringify({
          lang: selectLang
        })
      );
      setCurLang(selectLang);
    }
  }, []);
  return (
    <div className='w-full h-full flex items-center'>
      <Popover
        placement='bottomRight'
        trigger='click'
        open={open}
        onOpenChange={(v: boolean) => {
          setOpen(v);
        }}
        overlayInnerStyle={{ padding: '0px' }}
        content={() => {
          return (
            <div className='w-[200px] text-[#595C66] text-sm leading-5 py-1 font-DiDiSansPro-Regular'>
              {langOptionsValue?.map((lang: LangOptionType) => {
                return (
                  <div
                    className='px-3 py-[10px] cursor-pointer hover:bg-[#FFF7F2] hover:text-[#F76A31] hover:font-medium hover:font-DiDiSansPro-Medium'
                    key={lang.value}
                    onClick={async () => {
                      Omega.trackEvent('ibt_entrega_b_web_home_language_ck', {
                        type: lang.value
                      });
                      setOpen(false);
                      if (curLang?.value !== lang?.value) {
                        setCurLang(lang);
                        localStorage.setItem(
                          'commonLang',
                          JsonStringify({
                            lang
                          })
                        );
                        sessionStorage.removeItem(SessionType.windowLeaveValue);
                        await delay(1500); // 延时跳转，否则埋点会被浏览器取消
                        window.location.reload();
                      }
                    }}
                  >
                    {lang.label}
                  </div>
                );
              })}
            </div>
          );
        }}
      >
        <div className={`flex items-center ${open ? 'border-b-2 border-b-[#F76A31]' : ''}`}>
          <div
            className={`img_icon ${
              location.pathname === MenuConfig[MenuDict.Root].routePath
                ? 'icon_lang'
                : 'icon_lang_dark'
            }`}
          ></div>
          <div className='ml-2 text-xs font-DiDiSansPro-Regular'>{curLang?.name || '请选择'}</div>
        </div>
      </Popover>
    </div>
  );
};

export default LangSelect;
