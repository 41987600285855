export default {
  "Entrega_B2C_settings_payment_methods_changepaymentmethodtoast3": "Alterado com sucesso para pagamento com boleto",
  "Entrega_B2C_settings_payment_methods_changepaymentmethodtoast4": "Não é possível pagar com boleto no momento. Você pode continuar pagando os pedidos do {{99Entrega}} com cartão de crédito.",
  "Entrega_B2C_settings_payment_methods_changepaymentmethodtoast5": "Não é possível voltar a fazer pagamentos com cartão de crédito agora porque há uma fatura não paga. Veja sua fatura na página {{billspage}}.",
  "Entrega_B2C_newdelivery_popupbutton2": "Pagar agora",
  "Entrega_B2C_settings_payment_methods_changepaymentmethodtoast1": "Você já alterou o método de pagamento este mês. Tente novamente no próximo mês",
  "Entrega_B2C_newdelivery_popupbutton1": "Entendi",
  "Entrega_B2C_statement_actions_receipt": "Detalhes da solicitação",
  "Entrega_B2C_settings_payment_methods_changepaymentmethodtoast2": "Não é possível pagar com boleto no momento. Tente novamente após {{date}}",
  "Entrega_B2C_Accounting_Bills_Orderdetails_refund": "Reembolsado",
  "Entrega_B2C_cannot_cancel_": "Este pedido não pode ser cancelado. Atualize os detalhes do pedido",
  "Entrega_B2C_new_delivery_dropoff_information_validation": "Preencha os detalhes da entrega",
  "Entrega_B2C_Deliveries_list_ongoing": "Em andamento",
  "Entrega_B2C_Website_modifypassword": "Alterar senha",
  "Entrega_B2C_statement_AmountDue_fine": "Multa: {{fine}}",
  "Entrega_B2C_new_delivery_save_the_information_explanation": "Confirme se deseja salvar as informações antes de sair",
  "Entrega_B2C_Homepage_description_delivery_now": "Solicitar uma entrega",
  "Entrega_B2C_Registration_previousstep": "Voltar",
  "New_Category_Apps_details_yHOm_EWmJ": "Ocorreu um erro no pedido. Por favor, faça sua solicitação novamente.",
  "Entrega_B2C_new_delivery_dropoff_information_enter_contact_person_name": "Inserir nome do destinatário",
  "Entrega_B2C_Website_Portuguese": "Português",
  "Entrega_B2C_statement_AmountDue_interest": "Juros: {{interest}}",
  "Entrega_B2C_Accounting_debitnote": "Notas de débito",
  "Entrega_B2C_Accounting_Bills_list_PayID": "ID do pagamento",
  "Entrega_B2C_new_delivery_pickup_information_enter_complement": "Inserir complemento do endereço de coleta",
  "Entrega_B2C_new_delivery_payment_failure_updated_price": "Tentar novamente",
  "Entrega_B2C_Website_slogan": "Você precisa, {{99Entrega}}",
  "Entrega_B2C_Website_EN": "EN",
  "Entrega_B2C_Deliveries_list_Histories": "Concluídos",
  "Entrega_B2C_statement_actions_boleto": "Boleto",
  "Entrega_B2C_address_limitaion": "O complemento do endereço é muito longo. Por favor, ajuste.",
  "Entrega_B2C_statement_duedate_updated": "Atualizado",
  "Entrega_B2C_settings_payment_methods_boleto_apply": "Cadastrar",
  "Entrega_B2C_Deliveries_search_fillinrecepientaddress": "Insira o endereço do destinatário",
  "Entrega_B2C_Accounting_Bills_list_operation": "Ações",
  "Entrega_B2C_statement_download_failed": "Não foi possível baixar. Tente novamente.",
  "New_Category_Apps_Companyinfo_BHqW_SwQl": "E-mail",
  "Entrega_B2C_settings_payment_methods_pendingreview": "Análise pendente",
  "Entrega_B2C_Accounting_debitnote_totalamount": "Valor total",
  "Entrega_B2C_Homepage_description_newdelivery": "Crie um pedido para começar a usar nosso serviço de intermediação de entrega",
  "Entrega_B2C_Accounting_Bills_list_formofpayment": "Método de pagamento",
  "New_Category_Apps_details_ayEA_eBGp": "O código de coleta é a evidência de que o pedido está com o entregador. Forneça o código quando ele coletar o pacote",
  "Entrega_B2C_Accounting_Bills_list_status": "Status",
  "Entrega_B2C_history_order_cancel_failure": "Não foi possível cancelar o pedido",
  "Entrega_B2C_Accounting_debitnote_numofruns": "Total de pedidos",
  "Entrega_B2C_Deliveries_list_address": "Endereço",
  "Entrega_order_history_end_date": "Data de término",
  "Entrega_B2C_Website_ordernow": "Pedir agora",
  "Entrega_B2C_statement_AmountDue_newamount": "Novo valor: {{new}}",
  "Entrega_B2C_settings_payment_methods_boleto_residentiallimit": "Crédito disponível: {{left}}",
  "Entrega_B2C_Registration_estimatedvolume": "Volume mensal estimado de entregas",
  "Entrega_B2C_Accounting_Bills_bills": "Faturas",
  "New_Category_Apps_details_mQWr_dSmr": "Pedido encerrado",
  "EntregaB2C_settings_developer_testmode_apply_confirm": "Confirmar",
  "Entrega_B2C_new_delivery_pickup_information_enter_contact_number_validation": "Insira um número de telefone válido do remetente",
  "Entrega_B2C_debit_note_sucess_download": "Download da fatura realizado com sucesso",
  "Entrega_B2C_Accounting_Bills_Orderdetails_refundamount": "Reembolso",
  "Entrega_B2C_transactionhistory": "Histórico de pagamentos",
  "Entrega_B2C_Accounting_Bills_list_status_paid": "Paga",
  "Entrega_B2C_account": "Informações da empresa",
  "Entrega_B2C_bills_view": "Ver",
  "Entrega_B2C_Deliveries_search_recepientaddress": "Endereço do destinatário",
  "Entrega_B2C_statement_billingnumber": "ID da fatura",
  "Entrega_B2C_new_delivery_total_price": "Valor total",
  "New_Category_Apps_details_AWjw_cUlD": "O entregador chegará às {{time}}. Tenha o pacote em mãos",
  "Entrega_B2C_Deliveries_list": "Pedidos",
  "Entrega_B2C_settings_payment_methods_boleto_totallimit": "Limite total de crédito: {{total}}",
  "Entrega_B2C_Homepage_description_Bills": "Veja seu histórico de pagamentos completo",
  "Entrega_B2C_Homepage_description_details": "Ver detalhes",
  "Entrega_B2C_new_delivery_pickup_information_complement": "Complemento",
  "Entrega_B2C_new_delivery_payment_failure_updated_failed": "Não foi possível estimar o preço do pedido",
  "Entrega_B2C_new_delivery_save_the_information_leave_button": "Descartar",
  "Entrega_B2C_Accounting_Bills_Orderdetails_total": "Total {{amount}}",
  "Entrega_B2C_settings_payment_methods_boleto_paymentterm": "Prazo para pagamento: {{paymentterm}}",
  "Entrega_B2C_new_delivery_dropoff_information_contact_number": "Número de telefone do destinatário",
  "Entrega_B2C_Registration_companyname2": "Por favor, insira o nome da empresa",
  "Entrega_B2C_Registration_companyname1": "Insira o nome da empresa",
  "Entrega_B2C_new_delivery_pending_order": "Você tem um pedido não pago",
  "Entrega_B2C_new_delivery_add_pickup_details": "Adicionar detalhes",
  "Entrega_B2C_directory_newdelivery": "Novo pedido",
  "Entrega_B2C_networkbreakdown_toast1": "Ocorreu um erro. Tente novamente mais tarde",
  "Entrega_B2C_networkbreakdown_retry": "Tentar novamente",
  "Entrega_B2C_networkbreakdown_toast2": "Erro de rede. Atualize e tente novamente",
  "Entrega_B2C_Homepage_description_deliveris": "Visualize e gerencie todos os seus pedidos",
  "New_Category_Apps_details_Zoin_QyVr": "Horário da entrega: {{time}}",
  "Entrega_B2C_Registration_complement1": "por exemplo, sala 101",
  "Entrega_B2C_new_delivery_pickup_information_cancel": "Cancelar",
  "New_Category_Apps_details_AbyF_NMKH": "Aguardando um entregador aceitar",
  "Entrega_B2C_Registration_risknote": "Não foi possível realizar o cadastro devido a um erro nas informações da sua empresa",
  "Entrega_B2C_bills_reset": "Redefinir",
  "Entrega_order_history_status": "Status",
  "Entrega_B2C_directory_payment": "Informações de pagamento",
  "Entrega_B2C_Deliveries_list_cancel": "Cancelar",
  "Entrega_B2C_new_delivery_dropoff_information_enter_dropoff_address": "Inserir endereço de entrega",
  "Entrega_B2C_new_delivery_advanced": "Opções avançadas",
  "Entrega_B2C_new_delivery_order_cancel_timeout": "Um dos seus pedidos foi cancelado porque o pagamento não foi feito a tempo",
  "Entrega_B2C_new_delivery_enter_pickup_information_validation": "Insira o endereço de coleta",
  "Entrega_B2C_Settings_registrationdate_company": "Informações de cadastro da empresa",
  "Entrega_B2C_Deliveries_search_fillinrecepientphone": "Insira o telefone do destinatário",
  "Entrega_B2C_directory_deliveries": "Histórico de pedidos",
  "Entrega_B2C_networkbreakdown_toast": "Erro no sistema. Atualize e tente novamente",
  "Entrega_B2C_new_delivery_pickup_information_contact_number": "Número de telefone do remetente",
  "Entrega_B2C_debit_note_sucess_failure": "Não foi possível fazer o download da fatura",
  "Entrega_B2C_new_delivery_pickup_time": "Horário de preferência da coleta",
  "Entrega_B2C_statement_nodata": "Nenhuma fatura encontrada",
  "Entrega_B2C_Registration_companyaddress": "Endereço da empresa",
  "Entrega_B2C_Deliveries_search_fillinrecepientname": "Insira o nome do destinatário",
  "New_Category_Apps_details_gfOJ_AqBe": "O código de entrega é a evidência de que a entrega foi realizada. O destinatário deve fornecer esse código ao parceiro quando receber o pacote.",
  "Entrega_B2C_new_delivery_dropoff_information_complement": "Complemento",
  "Entrega_B2C_Registration_zipcode": "CEP",
  "Entrega_B2C_Registration_complement": "Complemento do endereço",
  "Entrega_B2C_new_delivery_pickup_code_details": "Informe ao entregador parceiro o código de coleta de quatro dígitos quando ele(a) chegar para a coleta",
  "Entrega_B2C_new_delivery_pickup_information_service_validation": "O endereço informado não está localizado na cidade selecionada. Tente outro endereço",
  "Entrega_B2C_Deliveries_list_price": "Preço",
  "Entrega_B2C_settings_payment_methods_changetoboletopopup_title": "Mudar para pagamento com boleto",
  "Entrega_B2C_newdelivery_popuptitle1": "Crédito insuficiente",
  "Entrega_B2C_newdelivery_popuptitle2": "Fatura em atraso",
  "Entrega_B2C_Accounting_Bills_list_Payday": "Hora e data",
  "New_Category_Apps_details_KSQo_BLSm": "Código de entrega",
  "Entrega_B2C_Website_signup": "Cadastrar",
  "Entrega_B2C_new_delivery_dropoff_information_choose_package_weight": "Escolha o peso",
  "New_Category_Apps_details_EQLy_QWsI": "ID da solicitação",
  "Entrega_B2C_Registration_city2": "Por favor, selecione uma cidade",
  "New_Category_Apps_details_tUHk_DCfd": "O entregador chegou. Esteja preparado(a) para entregar o pacote e ajude a evitar atrasos",
  "Entrega_B2C_Registration_city1": "Selecione uma cidade",
  "Entrega_B2C_Registration_operationinfo": "Informações comerciais",
  "Entrega_B2C_new_delivery_confirm_modification_city_explanation": "Depois de alterar a cidade, as informações atuais serão apagadas. Tem certeza de que deseja continuar?",
  "Entrega_B2C_Deliveries_list_emptylist": "Nenhum pedido por enquanto",
  "Entrega_B2C_Accounting_Bills_list_refundamount": "Reembolso",
  "Entrega_B2C_Registration_failure3": "Ver detalhes",
  "Entrega_B2C_Deliveries_search_status": "Selecione o status",
  "Entrega_B2C_Registration_failure1": "Não foi possível verificar as informações da sua empresa",
  "Entrega_B2C_Registration_failure2": "Clique no botão abaixo para ver mais detalhes. Você pode modificar as informações da empresa e enviar outra solicitação de verificação.",
  "Entrega_B2C_history_order_code": "Código",
  "Entrega_B2C_bills_search": "Pesquisar",
  "Entrega_B2C_Registration_companyname": "Razão social",
  "Entrega_B2C_Accounting_Bills_Orderdetails": "Detalhes",
  "Entrega_B2C__settings_payment_methods_managemycard": "Gerenciar",
  "Entrega_B2C_new_delivery_maximum_10_dropoff": "Até 10 endereços de entrega",
  "Entrega_B2C_Website_Portuguese1": "PT",
  "Entrega_B2C_settings_payment_methods_boleto_billingcycle_tip": "As faturas são geradas no primeiro dia útil de cada mês",
  "Entrega_B2C_statement_duedate": "Vencimento",
  "Entrega_B2C_new_delivery_pending_order_explanation": "Para fazer novos pedidos, pague o pedido pendente",
  "Entrega_B2C_new_delivery_order_now": "Pedir agora",
  "Entrega_B2C_new_delivery_add_dropoff_information": "Adicionar detalhes",
  "Entrega_B2C_Accounting_Bills_Orderdetails_refundtime": "Reembolsado em",
  "Entrega_B2C_new_delivery_pickup_address": "Endereço de coleta",
  "Entrega_B2C_settings_payment_methods_selectpaymentmethod": "Selecione um método de pagamento",
  "New_Category_Apps_details_ajoT_FBnL": "Pacote entregue",
  "Entrega_B2C_new_delivery_dropoff_information_category": "Tipo de pacote",
  "Entrega_B2C_Accounting_Bills_Orderdetails_moredetails": "Mais detalhes",
  "Entrega_order_history_start_date": "Data de início",
  "Entrega_B2C_new_delivery_pickup_information_validation": "Preencha os detalhes da coleta",
  "Entrega_B2C_debitnote_paymentmethod": "Método de pagamento",
  "Entrega_B2C_new_delivery_save_the_information": "Deseja salvar?",
  "Entrega_B2C_new_delivery_cancelwording": "Por favor, conte por que você quer cancelar o pedido e faremos o possível para melhorar sua experiência. O reembolso será creditado na sua conta de pagamento de 7 a 15 dias úteis",
  "New_Category_Apps_details_HRjt_Qzfv": "Ocorreu um erro no pedido. Por favor, faça sua solicitação novamente.",
  "Entrega_B2C_Accounting_Bills_Orderdetails_totalamount": "Valor total",
  "Entrega_B2C_new_delivery_pickup_information_enter_Notes": "Insira aqui observações, como o tamanho do item (se for um item volumoso) ou outras informações que o entregador precise saber antes da coleta. Essas informações serão compartilhadas com o entregador parceiro. Não insira informações confidenciais.",
  "Entrega_B2C_new_delivery_pickup_information_enter_contact_person_name_validation": "Insira o nome do remetente",
  "New_Category_Apps_details_qIYs_kBzP": "Estamos procurando um entregador para você. Por favor, aguarde um momento.",
  "Entrega_B2C_newdelivery_insufficientresidualcredit": "Crédito disponível insuficiente: {{residual_credit}}",
  "Entrega_B2C_new_delivery_confirm_modification_city_modify_button": "Alterar",
  "Entrega_B2C_Registration_number": "Número",
  "New_Category_Apps_details_PfKj_ULAj": "Entrega em andamento",
  "Entrega_B2C_Accounting_Bills_list_pay": "Pagar",
  "Entrega_B2C_Registration_zipcode2": "Por favor, insira o CEP no seguinte formato: XXXXX-XXX",
  "Entrega_B2C_Registration_zipcode1": "Insira o CEP no formato XXXXX-XXX",
  "Entrega_B2C_new_delivery_dropoff_information_enter_contact_number": "Inserir número de telefone do destinatário",
  "New_Category_Apps_details_UDqE_blno": "Detalhes do endereço",
  "Entrega_B2C_new_delivery_save_the_information_save_button": "Salvar",
  "Entrega_B2C_new_delivery_confirm_modification_city": "Confirmar alteração de cidade",
  "Entrega_B2C_Registration_zipcode3": "Formato de CEP inválido. Verifique e tente novamente",
  "Entrega_B2C_Deliveries_search_recepientphone": "Telefone do destinatário",
  "Entrega_B2C_Registration_submittip3": "Enviaremos por e-mail o resultado do seu cadastro em até três dias úteis.",
  "Entrega_B2C_Registration_submittip2": "Estamos ansiosos por essa parceria e para ajudar você a atingir suas metas de entrega!",
  "Entrega_B2C_Registration_submittip1": "Informações enviadas",
  "New_Category_Apps_orders_XPKr_IJRz": "Informações do pacote",
  "Entrega_B2C_new_delivery_dropoff_information_enter_Notes": "Insira aqui observações, como o tamanho do item (se for um item volumoso) ou outras informações que o entregador precise saber antes da coleta. Essas informações serão compartilhadas com o entregador parceiro. Não insira informações confidenciais.",
  "Entrega_B2C_new_delivery_dropoff_information_enter_dropoff_address_validation": "Insira o endereço de entrega",
  "New_Category_Apps_details_lggF_KuLf": "Informações do pacote",
  "Entrega_B2C_Website_99Entrega": "99Entrega",
  "Entrega_B2C_Registration_city": "Cidade",
  "Entrega_B2C_statement_AmountDue": "A pagar",
  "Entrega_B2C_Website_introduction": "Aproveite serviços de entrega rápidos, seguros e descomplicados",
  "Entrega_B2C_Accounting_Bills_list_status_tobepaid": "Não paga",
  "Entrega_B2C_statement_AmountDue_originalamount": "Valor original: {{old}}",
  "Entrega_B2C_history_order_cancel_success": "Pedido cancelado com sucesso",
  "Entrega_B2C_Deliveries_search_recepientname": "Nome do destinatário",
  "Entrega_B2C_Accounting_Bills_Orderdetails_priceinformation": "Valor detalhado",
  "Entrega_B2C_new_delivery_code_details": "Informe ao destinatário o código de entrega de quatro dígitos que deve ser apresentado ao entregador na chegada",
  "Entrega_B2C_Deliveries_list_pickuptime": "Horário de coleta",
  "Entrega_B2C_new_delivery_delivery_code": "Código de entrega",
  "Entrega_B2C_Registration_startusing5": "Os entregadores parceiros se engajam para que as mercadorias sejam entregues em segurança, com rastreamento de localização em tempo real durante o processo de entrega.",
  "Entrega_B2C_new_delivery_dropoff_information_package_weight": "Peso",
  "Entrega_B2C_Registration_basicinfo": "Informações da empresa",
  "New_Category_Apps_details_cprL_wHtG": "Informações do entregador",
  "Entrega_B2C_bills_date": "Período",
  "Entrega_B2C_new_delivery_pending_order_to_pay_button": "Pagar agora",
  "Entrega_B2C_new_delivery_dropoff_information_enter_contact_person_name_validation": "Insira um nome de destinatário válido",
  "Entrega_B2C_statement_enterbillingnumber": "Insira o ID da fatura",
  "Entrega_B2C_Accounting_Bills_Orderdetails_orderID": "ID do pedido",
  "Entrega_B2C_new_delivery_dropoff_information": "Detalhes da entrega",
  "New_Category_Apps_details_FqMP_biOY": "Cancelar pedido",
  "Entrega_B2C_settings_payment_methods_changepaymentmethodpopup_content": "Tem certeza de que deseja se cadastrar para pagamento com boleto? Analisaremos sua elegibilidade em até três dias úteis e notificaremos você sobre o resultado o mais rápido possível.",
  "Entrega_B2C_Registration_startusing4": "Preços acessíveis",
  "Entrega_B2C_Registration_startusing3": "Faça pedidos com praticidade diretamente pelo site",
  "Entrega_B2C_Settings_registrationdate_mainuser_email": "Telefone",
  "Entrega_B2C_Registration_startusing2": "Cadastre-se para fazer entregas sob demanda aos seus clientes",
  "Entrega_B2C_new_delivery_cancel_button1": "Cancelar pedido",
  "Entrega_B2C_Registration_startusing1": "Comece a usar o {{99Entrega}}",
  "Entrega_B2C_Homepage_description_settings": "Gerencie as configurações do sistema",
  "Entrega_B2C_statement_status": "Status da fatura",
  "Entrega_B2C_new_delivery_dropoff_information_contact_person_name": "Nome do destinatário",
  "Entrega_B2C_new_delivery_cancel_button3": "Voltar",
  "New_Category_Apps_details_YLwR_BPBA": "Código de coleta",
  "Entrega_B2C_new_delivery_dropoff_information_dropoff_address": "Endereço de entrega",
  "Entrega_B2C_new_delivery_cancel_button2": "Voltar",
  "Entrega_B2C_Accounting_debitnote_period": "Período de faturamento",
  "Entrega_B2C_Registration_submit": "Enviar",
  "Entrega_B2C_new_delivery_pickup_information_enter_contact_person_name": "Inserir nome do remetente",
  "Entrega_B2C_new_delivery_cancel_button4": "Cancelar pedido",
  "Entrega_B2C_Homepage_description_Debitnote": "Baixe suas notas de débito",
  "Entrega_B2C_settings_payment_methods_changetocreditcardpopup_title": "Mudar para pagamento com cartão de crédito",
  "Entrega_B2C_Settings_registrationdate_company_reject": "Não foi possível realizar o cadastro. Motivo: {{reject_reason}}",
  "EntregaB2C_settings_developer_onlinemode_cancel": "Cancelar",
  "Entrega_B2C_Accounting_Bills_Orderdetails_orderinformation": "Informações do pedido",
  "Entrega_B2C_new_delivery_dropoff_information_enter_contact_number_validation": "Insira um número de telefone de destinatário válido",
  "Entrega_B2C_statement_duedate_originaldate": "Data original: {{old}}",
  "Entrega_B2C_new_delivery_add_dropoff": "Adicionar outro endereço de entrega",
  "Entrega_B2C_Registration_CNPJ": "CNPJ",
  "New_Category_Apps_details_kOXX_JIxu": "Aguardando um entregador aceitar",
  "Entrega_B2C_Accounting_Bills_list_status_cancelled": "Cancelada",
  "Entrega_B2C_Accounting_Bills_list_cancelsuccess": "Pedido cancelado. Atualize a página",
  "Entrega_B2C_company_name_limitaion": "O nome da empresa é muito longo. Por favor, ajuste.",
  "Entrega_B2C_settings_payment_methods_selectpaymentmethod_tip": "Só é possível alterar método de pagamento uma vez por mês",
  "Entrega_B2C_new_delivery_pickup_information_contact_person_name": "Nome do remetente",
  "Entrega_B2C_new_delivery_confirm_modification_city_cancel_button": "Cancelar",
  "Entrega_B2C_new_delivery_pickup_information_save": "Salvar",
  "Entrega_B2C_new_delivery_pickup_code": "Código de coleta",
  "Entrega_B2C_Registration_nextstep": "Próximo",
  "Entrega_B2C_Registration_estimatedvolume1": "Selecione o volume mensal estimado de entregas",
  "Entrega_B2C_Registration_estimatedvolume2": "Por favor, selecione o volume mensal estimado de entregas",
  "Entrega_B2C_directory_setting": "Configurações",
  "Entrega_B2C_settings_payment_methods_boleto_billingcycle": "Período de faturamento: {{Billingcycle}}",
  "Entrega_B2C_statement_AmountPaid": "Pago",
  "Entrega_B2C_newdelivery_residualcredit": "Crédito disponível: {{residual_credit}}",
  "Entrega_B2C_Deliveries_list_orderagain": "Pedir novamente",
  "Entrega_B2C_Registration_number1": "Insira o número do estabelecimento",
  "Entrega_B2C_Registration_number2": "Por favor, insira o número do estabelecimento",
  "Entrega_B2C_new_delivery_pickup_time_now": "Agora",
  "Entrega_B2C_Website_login": "Entrar",
  "Entrega_B2C_Website_logout": "Sair",
  "Entrega_B2C_statement_bubble": "Se você tiver dúvidas sobre uma fatura, clique aqui para falar com o Atendimento ao Cliente.",
  "Entrega_B2C_Registration_CNPJ4": "Formato de CNPJ inválido. Verifique e tente novamente",
  "Entrega_B2C_Registration_businesstype1": "Selecione uma categoria",
  "Entrega_B2C_Registration_businesstype2": "Por favor, selecione uma categoria",
  "Entrega_B2C_Registration_CNPJ2": "Por favor, insira o CNPJ",
  "Entrega_B2C_Registration_CNPJ1": "Insira o CNPJ no formato XX.XXX.XXX/XXXX-XX",
  "Entrega_B2C_new_delivery_payment_failure_updated_failed_explanation": "Não foi possível estimar o preço do pedido. Clique no botão para tentar novamente",
  "Entrega_B2C_directory_home": "Início",
  "Entrega_B2C_Deliveries_detail_ordercreationtime": "Horário da criação do pedido",
  "Entrega_B2C_Website_English": "English",
  "New_Category_Apps_details_VmiH_KcYh": "Horário estimado de entrega: {{time}}",
  "New_Category_Apps_details_BpIB_HITO": "Estamos procurando um entregador. Aguarde um momento",
  "Entrega_B2C_new_delivery_dropoff_information_enter_complement": "Inserir complemento do endereço de entrega",
  "Entrega_B2C__settings_payment_methods_creditcard": "Cartão de crédito",
  "Entrega_B2C_statement": "Faturas",
  "Entrega_B2C_Settings_registrationdate_mainuser": "Informações de cadastro do usuário",
  "Entrega_B2C_debitnote_selectpaymentmethod": "Selecione um método de pagamento",
  "Entrega_B2C_settings_payment_methods_changetocreditcardpopup_content": "Se você mudar para pagamento com cartão de crédito e quiser usar pagamento com boleto novamente mais tarde, será necessário se cadastrar novamente. Deseja continuar?",
  "Entrega_B2C_Registration_companyaddress2": "Por favor, insira o endereço da sua empresa",
  "Entrega_B2C_new_delivery_vehicle_types": "Tipo de veículo",
  "Entrega_B2C_directory_payment_methods": "Configurações de pagamento",
  "Entrega_B2C_Accounting_Bills_list_amountpaid": "Valor pago",
  "New_Category_Apps_details_cSAS_LrRJ": "Detalhes da solicitação",
  "Entrega_B2C_new_delivery_enter_pickup_information": "Inserir endereço de coleta",
  "Entrega_B2C_Registration_companyaddress1": "Insira o endereço da empresa",
  "Entrega_B2C_Registration_businesstype": "Categoria",
  "Entrega_B2C_Accounting_debitnote_nodebitnote": "Não há notas de débito disponíveis",
  "Entrega_B2C_new_delivery_pickup_information_Notes": "Observações",
  "Entrega_B2C_new_delivery_pickup_information": "Detalhes da coleta",
  "Entrega_B2C_statement_duedate_newdate": "Nova data: {{new}}",
  "Entrega_B2C_Accounting_Bills_Orderdetails_createtime": "Criado em {{yyyymmddhh}}",
  "Entrega_B2C_statement_download_success": "Baixado com sucesso",
  "Entrega_B2C_new_delivery_dropoff_information_category_validation": "Selecione um tipo de item",
  "Entrega_B2C_newdelivery_popupcontent1": "Este pedido não pode ser enviado porque seu perfil não tem crédito suficiente disponível para pagar a taxa de entrega.",
  "Entrega_B2C_newdelivery_popupcontent2": "Não é possível usar os serviços de entrega no momento porque há uma fatura em atraso. Pague sua fatura e tente novamente. Para dúvidas relacionadas ao faturamento, fale com o Atendimento ao Cliente.",
  "Entrega_B2C_Accounting_Bills_list_nobills": "Nenhuma fatura disponível",
  "Entrega_B2C_new_delivery_cancel_title1": "Cancelar pedido",
  "Entrega_B2C_Deliveries_list_status_accepted": "Aceito",
  "Entrega_B2C_new_delivery_cancel_title2": "Tem certeza de que deseja cancelar este pedido?",
  "Entrega_B2C_statement_selectstatus": "Selecione um status de fatura",
  "Entrega_B2C_new_delivery_order_information": "Informações do novo pedido",
  "Entrega_B2C_delivery_account_block": "Devido a um problema com esta conta, não é possível fazer pedidos no momento",
  "Entrega_B2C_notes_limitaion": "A observação é muito longa. Por favor, ajuste.",
  "New_Category_Apps_details_VZqW_ZPZb": "Pedido cancelado",
  "Entrega_B2C_Accounting_Bills_accounting": "Faturamento",
  "Entrega_B2C_Accounting_Bills_Orderdetails_paytime": "Pago em {{yyyymmddhh}}",
  "Entrega_B2C_new_delivery_cancelwording2": "Se a taxa de entrega tiver sido paga com cartão de crédito, o reembolso será creditado na sua conta para pagamento de 7 a 15 dias úteis",
  "Entrega_B2C_settings_payment_methods_boleto": "Boleto",
  "Entrega_B2C_toast2": "Sem resultados por enquanto",
  "Entrega_B2C_toast1": "Não foi possível obter dados. Tente novamente",
  "Entrega_B2C_Accounting_debitnote_download": "Baixar",
  "Entrega_B2C_new_delivery_pickup_information_enter_contact_number": "Inserir número de telefone do remetente",
  "Entrega_B2C_statement_actions_debitnote": "Nota de débito",
  "Entrega_B2C_Deliveries_list_orderID": "ID do pedido"
}