// ------------ 以上为示例，仅供参考，具体用法可查阅文档 https://tutorial.jotai.org/
import { atom } from 'jotai';

import { ConfigureRes, InfoRes } from '@/api/common.type';
import { $t } from '@/lib/i18n';
import { defaultCountry } from '@/configs';

// 这里存放全局共享的状态，局部使用的可以维护在自己的页面模块中

export const commonConfigAtom = atom<ConfigureRes | undefined>(undefined);

export interface LangOptionType {
  value: string;
  label: string;
  name: string;
}
export const langOptionsAtom = atom<Array<LangOptionType>>(
  localStorage.getItem('devTool')
    ? [
        {
          value: 'pt-BR',
          label: $t('Entrega_B2C_Website_Portuguese') || 'Português',
          name: $t('Entrega_B2C_Website_Portuguese1') || 'BR'
        },
        {
          value: 'en-US',
          label: $t('Entrega_B2C_Website_English') || 'English',
          name: $t('Entrega_B2C_Website_EN') || 'EN'
        },
        {
          value: 'zh-CN',
          label: '中文',
          name: 'ZH'
        }
      ]
    : [
        {
          value: 'pt-BR',
          label: $t('Entrega_B2C_Website_Portuguese') || 'Português',
          name: $t('Entrega_B2C_Website_Portuguese1') || 'BR'
        },
        {
          value: 'en-US',
          label: $t('Entrega_B2C_Website_English') || 'English',
          name: $t('Entrega_B2C_Website_EN') || 'EN'
        }
      ]
);
export interface CityOpenCityOptionType {
  city_id: number;
  city_name: string;
}

export interface CityOpenCountryOptionType {
  country_code: string;
  country_name: string;
  cities: CityOpenCityOptionType[];
}
export const cityOpenOptionsAtom = atom<Array<CityOpenCountryOptionType>>([defaultCountry]);

export const globalLoadingAtom = atom<boolean>(false);
export const userInfoAtom = atom<InfoRes | undefined>(undefined);
