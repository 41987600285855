import { useAtom } from 'jotai';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Spin } from '@didi-pebble/pebble-design-react';

import { userInfoAtom } from '@/store/common';
import { CommonApiModule } from '@/api/common.api';
import { ApiErrorCode, Omega } from '@/lib';
import { JsonStringify } from '@/utils';
import { MenuConfig, MenuDict } from '@/components/layouts/page/config';
import { SystemError } from '@/components/systemError';
import { ReviewStatusDic } from '@/types/company';

const PermissionsWrap = (props: { children: any }) => {
  const { children } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [userInfoValue, setUserInfoValue] = useAtom(userInfoAtom);
  const [loading, setLoading] = useState(true);
  /** 获取用户信息，校验登录 */
  const getUserInfo = async () => {
    setLoading(true);

    try {
      const { data, errno } = await CommonApiModule.getInfo();

      if (errno !== ApiErrorCode.loginInvalid) {
        localStorage.setItem('userInfo', JsonStringify(data));
        if (
          /** 当前访问非公司注册页；并且已登录，但未审核通过 */
          ![MenuConfig[MenuDict.NewCompany]?.routePath]?.includes(location.pathname) &&
          (!data?.companies ||
            data?.companies?.length === 0 ||
            data?.companies?.[0]?.status < ReviewStatusDic.reviewSuccess)
        ) {
          const params = new URLSearchParams(window.location.search);
          const disableJump = params.get('disableJump');
          /**
           * url不带disableJump=1，都跳转
           *
           * url带disableJump=1
           * 1.访问首页，不跳转公司注册页
           * 2.访问公司注册页，不跳转
           * 3.访问其他页面，跳转公司注册页
           */

          if (!disableJump || location.pathname !== MenuConfig[MenuDict.Root]?.routePath) {
            navigate(MenuConfig[MenuDict.NewCompany]?.routePath);
          }
        }
        setLoading(false);
        setUserInfoValue(data);
      }
      // errno === 401 时，不会setLoading(false);在Loading状态下跳转passport页
    } catch (e: any) {
      const { errno } = e?.response?.data || {};

      if (!errno) {
        Omega.trackBusiness('tech_entrega_global', {
          business: 'getUserInfo',
          type: 'op',
          status: e?.response?.status,
          msg: e?.message || e?.response?.statusText || e
        });
      }
      if (errno !== ApiErrorCode.loginInvalid) {
        setLoading(false);
      }

      // message.error($t('Entrega_B2C_networkbreakdown_toast') || '系统异常，请刷新重试');
    }
  };

  useEffect(() => {
    getUserInfo();
  }, [location]);
  return (
    <div className='h-full permissions_wrap'>
      {location.pathname !== MenuConfig[MenuDict.Root]?.routePath && !userInfoValue?.uid ? (
        <div className='h-full'>
          {loading && (
            <div className='h-full flex justify-center items-center'>
              <Spin spinning={loading}></Spin>
            </div>
          )}
          {!loading && (
            <div className='h-full'>
              <div className='h-full'>
                <SystemError onClick={getUserInfo}></SystemError>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className='h-full'>{children}</div>
      )}
    </div>
  );
};

export default PermissionsWrap;
