import React, { Suspense, useEffect } from 'react';
import { RouteObject, useLocation, useRoutes } from 'react-router-dom';
import { Layout, Spin, Switch, Tooltip } from '@didi-pebble/pebble-design-react';
import { useAtom } from 'jotai';

import HeaderComp from '@/components/layouts/header';
import { commonConfigAtom } from '@/store/common';
import PermissionsWrap from '@/components/layouts/permissionsWrap';

import ROUTER_CONFIG from './router';
import { SyncRouteObject } from './types';
import { CommonApiModule } from './api/common.api';
import { JsonStringify } from './utils';
import { Omega } from './lib';

const { Content } = Layout;

const App: React.FC = () => {
  const location = useLocation();
  const [, setCommonConfigValue] = useAtom(commonConfigAtom);

  /** 获取全局配置 */
  const getCommonConfig = async () => {
    try {
      const { data } = await CommonApiModule.getConfigure();

      setCommonConfigValue(data);
    } catch (e) {
      /** 不处理异常。
       * 由用到数据的业务组件中自行处理
       */
      console.log(e);
    }
  };

  useEffect(() => {
    Omega.init();
    getCommonConfig();
    try {
      const params = new URLSearchParams(window.location.search);
      const entry = params.get('entry');

      sessionStorage.setItem('entry', entry || 'web');
    } catch (e) {
      console.log(e);
    }
  }, []);
  useEffect(() => {
    sessionStorage.setItem('location', JsonStringify(location));
  }, [location]);
  /**
   * 异步加载路由组件
   */
  const syncRouter = (table: SyncRouteObject[]): RouteObject[] => {
    const mRouteTable: RouteObject[] = [];

    table.forEach(route => {
      mRouteTable.push({
        path: route.path,
        element: (
          <Suspense
            fallback={
              <div className='w-full h-full flex items-center justify-center'>
                <Spin></Spin>
              </div>
            }
          >
            <PermissionsWrap>
              <route.element />
            </PermissionsWrap>
          </Suspense>
        ),
        children: route.children && syncRouter(route.children)
      });
    });
    return mRouteTable;
  };

  const appRoutesElement = useRoutes(syncRouter(ROUTER_CONFIG));

  return (
    <Layout className='w-[100%] h-[100%]'>
      <HeaderComp />
      <Content className='w-[100%] h-[400px]' style={{ background: '#f3f5f8' }}>
        {appRoutesElement}
      </Content>
      {/* 研发工具：测试时查看文案翻译 */}
      {localStorage.getItem('devTool') && (
        <div className='absolute bottom-10 right-10'>
          <div>
            <Tooltip title='研发工具：localStorage中devTool有值会显示此开关。用于切换是否显示文案key'>
              <Switch
                checked={Boolean(localStorage.getItem('showI18nKey'))}
                onChange={checked => {
                  if (checked) {
                    localStorage.setItem('showI18nKey', String(checked));
                  } else {
                    localStorage.removeItem('showI18nKey');
                  }
                  window.location.reload();
                }}
              />
            </Tooltip>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default App;
