import { Http, CustomRequestConfig } from '@/lib/http';

import {
  AllRes,
  OpenRes,
  ConfigureRes,
  InfoRes,
  ConfigRes,
  SearchQuery,
  SearchRes,
  GeocodeQuery,
  GeocodeRes,
  DocumentIdReqBody,
  DocumentIdRes,
  CardUrlRes
} from './common.type';

export const API_URL = {
  /* 获取全部城市列表(填写公司页面的城市列表） */
  AllURL: '/v1/city/all',
  /* 获取开城的城市列表（顶部导航） */
  OpenURL: '/v1/city/open',
  /* 获取基础配置信息 */
  ConfigureURL: '/v1/configure',
  /* 获取用户信息接口(可通过该接口判断是否登录） */
  InfoURL: '/v1/user/info',
  /* 获取客服配置 */
  ConfigURL: '/v1/cx/config',
  /* 地址模糊匹配接口 */
  SearchURL: '/v1/address/search',
  /* 根据poi_id获取位置信息 */
  GeocodeURL: '/v1/address/geocode',
  /* 校验cnpj合法 */
  DocumentIdURL: '/v1/company/check/document-id',
  /* 获取绑卡跳转链接 */
  CardUrlURL: '/v1/payment/card_url'
};

export class CommonApiModule {
  /**
   * 获取全部城市列表(填写公司页面的城市列表）
   * @link 接口文档 https://xmock.intra.xiaojukeji.com/project/20681/interface/api/840259
   * @updateTime 1970/1/21 02:33:21
   */
  static getAll(exParams?: CustomRequestConfig) {
    return Http<AllRes>({
      method: 'GET',
      url: API_URL.AllURL,
      ...exParams
    });
  }

  /**
   * 获取开城的城市列表（顶部导航）
   * @link 接口文档 https://xmock.intra.xiaojukeji.com/project/20681/interface/api/840263
   * @updateTime 1970/1/21 01:54:48
   */
  static getOpen(exParams?: CustomRequestConfig) {
    return Http<OpenRes>({
      method: 'GET',
      url: API_URL.OpenURL,
      ...exParams
    });
  }

  /**
   * 获取基础配置信息
   * @link 接口文档 https://xmock.intra.xiaojukeji.com/project/20681/interface/api/840278
   * @updateTime 1970/1/21 03:08:09
   */
  static getConfigure(exParams?: CustomRequestConfig) {
    return Http<ConfigureRes>({
      method: 'GET',
      url: API_URL.ConfigureURL,
      ...exParams
    });
  }

  /**
   * 获取用户信息接口(可通过该接口判断是否登录）
   * @link 接口文档 https://xmock.intra.xiaojukeji.com/project/20681/interface/api/840287
   * @updateTime 1970/1/21 03:02:04
   */
  static getInfo(exParams?: CustomRequestConfig) {
    return Http<InfoRes>({
      method: 'GET',
      url: API_URL.InfoURL,
      ...exParams
    });
  }

  /**
   * 获取客服配置
   * @link 接口文档 https://xmock.intra.xiaojukeji.com/project/20681/interface/api/840352
   * @updateTime 1970/1/21 03:11:12
   */
  static getConfig(exParams?: CustomRequestConfig) {
    return Http<ConfigRes>({
      method: 'GET',
      url: API_URL.ConfigURL,
      ...exParams
    });
  }

  /**
   * 地址模糊匹配接口
   * @link 接口文档 https://xmock.intra.xiaojukeji.com/project/20681/interface/api/840926
   * @updateTime 1970/1/21 03:03:53
   */
  static getSearch(params: SearchQuery, exParams?: CustomRequestConfig) {
    return Http<SearchRes>({
      method: 'GET',
      url: API_URL.SearchURL,
      params,
      ...exParams
    });
  }

  /**
   * 根据poi_id获取位置信息
   * @link 接口文档 https://xmock.intra.xiaojukeji.com/project/20681/interface/api/841649
   * @updateTime 1970/1/21 02:52:26
   */
  static getGeocode(params: GeocodeQuery, exParams?: CustomRequestConfig) {
    return Http<GeocodeRes>({
      method: 'GET',
      url: API_URL.GeocodeURL,
      params,
      ...exParams
    });
  }

  /**
   * 校验cnpj合法
   * @link 接口文档 https://xmock.intra.xiaojukeji.com/project/20681/interface/api/845080
   * @updateTime 1970/1/21 02:14:47
   */
  static postDocumentId(data: DocumentIdReqBody, exParams?: CustomRequestConfig) {
    return Http<DocumentIdRes>({
      method: 'POST',
      url: API_URL.DocumentIdURL,
      data,
      ...exParams
    });
  }

  /**
   * 获取绑卡跳转链接
   * @link 接口文档 https://xmock.intra.xiaojukeji.com/project/20681/interface/api/862647
   * @updateTime 1970/1/21 02:42:16
   */
  static getCardUrl(exParams?: CustomRequestConfig) {
    return Http<CardUrlRes>({
      method: 'GET',
      url: API_URL.CardUrlURL,
      ...exParams
    });
  }

  /**
   * 获取客服消息数
   * 注意！！！这个接口是手工写的，不是自动从mock拉取的
   */
  static getCsMsg(params: { uid?: string }) {
    return Http<{
      csMsgCount: number;
    }>({
      method: 'GET',
      url: process.env.csMsgUrl,
      params
    });
  }
}
