/**
 * 公共配置、字典、常量(如枚举)
 */

// 上线前要找后端确认，文案和city_id是否正确
export const defaultCountry = {
  country_code: 'BR', // 国家码
  country_name: 'Brasil', // 国家名
  // 国家下的城市列表
  cities: [
    {
      city_id: 55000199,
      city_name: 'São Paulo'
    }
  ]
};
export const defaultCity = {
  city_id: 55000199,
  city_name: 'São Paulo'
};

export const defaultPhoneCode = '+55';

export enum SessionType {
  windowLeaveValue = 'windowLeaveValue' // 在发单页和公司注册页，直接关闭窗口需要弹出浏览器原生的离开拦截。这个值来控制是否需要弹出拦截，true为需要
}
export enum LocalStorageConstant {
  devTool = 'devTool',
  showI18nKey = 'showI18nKey',
  web_id = 'web_id',
  b2c_web_version = 'b2c_web_version',
  commonCity = 'commonCity',
  commonLang = 'commonLang',
  hasConfirmCsIcon = 'hasConfirmCsIcon',
  userInfo = 'userInfo',
  showAproveStatusTip = 'showAproveStatusTip',
  user_lat = 'user_lat',
  user_lng = 'user_lng',
  newDeliver = 'newDeliver'
}

export enum SessionStorageConstant {
  location = 'location',
  entry = 'entry',
  windowLeaveValue = 'windowLeaveValue',
  newDeliver = 'newDeliver'
}

export default {};
