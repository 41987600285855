import { Button } from '@didi-pebble/pebble-design-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { useAtom } from 'jotai';

import { getPassportUrl } from '@/lib/passport';
import { $t } from '@/lib/i18n';
import { userInfoAtom } from '@/store/common';
import { Omega } from '@/lib';
import { delay } from '@/utils';

import CitySelect from './citySelect';
import LangSelect from './langSelect';
import CsComp from './csComp';
import AvatarComp from './avatarComp';
import { MenuConfig, MenuDict } from '../page/config';
import './index.less';

const HeaderComp: React.FC = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const [userInfoValue] = useAtom(userInfoAtom);
  const isRoot = useMemo(() => {
    return location?.pathname === MenuConfig[MenuDict.Root].routePath;
  }, [location]);

  return (
    <div
      className={`h-12 flex justify-between items-center px-6 ${
        isRoot ? `px-[120px] rootBg bg-white/50` : `bg-[#282B33] text-[#C2C4CC]`
      }`}
    >
      <div
        className='flex items-center cursor-pointer	'
        onClick={() => {
          navigate(`${MenuConfig[MenuDict.Root].routePath}?disableJump=1`);
        }}
      >
        {isRoot ? (
          <div className='img_icon icon_99logo_black'></div>
        ) : (
          <div className='img_icon icon_99logo_white'></div>
        )}
        <span
          className='text-white font-DiDiSansPro-Medium text-xl leading-[30px] ml-[8px] font-medium'
          style={isRoot ? { color: '#282B33' } : {}}
        ></span>
      </div>
      <div className='flex cursor-pointer'>
        {userInfoValue?.uid && (
          <div className='ml-6'>
            <CsComp></CsComp>
          </div>
        )}
        <div className='ml-[12px]'>
          <LangSelect></LangSelect>
        </div>
        <div className='ml-6'>
          <CitySelect></CitySelect>
        </div>
        {!userInfoValue?.uid && (
          <div
            className='ml-6'
            onClick={async () => {
              Omega.trackEvent('ibt_entrega_b_web_home_login_ck');
              await delay(1000); // 延时跳转，否则埋点会被浏览器取消
              window.location.href = getPassportUrl().login;
            }}
          >
            <div className='w-full h-full flex items-center'>
              <div className='img_icon icon_login'></div>
              <div className='ml-2 text-xs font-DiDiSansPro-Regular'>
                {$t('Entrega_B2C_Website_login') || '登录'}
              </div>
            </div>
          </div>
        )}
        {!userInfoValue?.uid && (
          <div className='ml-6 text-sm'>
            <Button
              type='primary'
              onClick={async () => {
                Omega.trackEvent('ibt_entrega_b_web_home_signup_ck');
                await delay(1000); // 延时跳转，否则埋点会被浏览器取消
                window.location.href = getPassportUrl().register;
              }}
            >
              {$t('Entrega_B2C_Website_signup') || '注册'}
            </Button>
          </div>
        )}

        {userInfoValue?.uid && (
          <div className='ml-6'>
            <AvatarComp></AvatarComp>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderComp;
