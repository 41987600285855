import { useRef } from 'react';

import { CommonRes } from '@/lib';

/**
 * 公共hooks
 */
export default {};

interface UsePollingProps {
  funLog?: any;
  func: () => Promise<{
    hasError: boolean;
    apiRes?: CommonRes<any>;
    stopPolling?: boolean; // 额外的强制停止轮询
  }>;
  intervalFe?: number; // 前端强制轮询时间，优先级高于后端返回轮询时间
}
export const usePolling = (props: UsePollingProps) => {
  const { func, intervalFe, funLog } = props;
  const timerInterval = useRef(0);
  const timer: any = useRef(null);
  const poolReq = () => {
    timer.current = setTimeout(async () => {
      funLog && funLog();
      if (document.visibilityState === 'visible') {
        try {
          const { hasError, apiRes, stopPolling } = await func();

          if (hasError) {
            if (intervalFe) timerInterval.current = intervalFe;
            if (timerInterval.current > 0) {
              timer.current && poolReq();
            }
          } else {
            const { polling_interval: intervalBe } = apiRes?.data || {}; // polling_interval为后端返回的轮询时间

            if (stopPolling) {
              timerInterval.current = 0;
            } else {
              timerInterval.current = intervalFe || intervalBe * 1000 || 0;
            }

            if (timerInterval.current > 0) {
              timer.current && poolReq();
            }
          }
        } catch (e) {
          console.log(e, '轮询异常');
        }
      } else {
        timer.current && poolReq();
      }
    }, timerInterval.current);
  };

  return {
    run: () => {
      clearTimeout(timer.current);
      timer.current = null;
      poolReq();
    },
    cancel: () => {
      clearTimeout(timer.current);
      timer.current = null;
    }
  };
};
