import I18N, { i18nStart, i18nSetLang } from '@didi/i18n-plugin';

import localesInfo from '@/locales/i18n-info';
import localesIndex from '@/locales/i18n-index';
import { JsonParse } from '@/utils';

const commonLang = JsonParse(localStorage.getItem('commonLang') as string);

i18nSetLang(commonLang?.lang?.value || 'pt-BR'); //  'pt-BR', 'en-US', 'zh-CN'
i18nStart(localesIndex, localesInfo);

/**
 * 替换占位符
 * @param text 例：'hello, {{name}}'
 * @param values 例：{ name: 'Tom'}
 * @returns 例：'hello, Tom'
 */
function formatTextMessage(text: string, values: any) {
  const regex = /{{(.*?)}}/g;

  const result = text.replace(regex, function (match, p1) {
    const replacement = values[p1.trim()];

    return replacement !== undefined || replacement === '' ? replacement : match;
  });

  return result;
}

/**
 * 多语言翻译
 * @param key 例：Entrega_B2C_Accounting_Bills_Orderdetails_createtime
 * @param value 例：{ yyyymmddhh: 2023-12-01 11:00:00 }
 * @returns 例: 订单创建时间：2023-12-01 11:00:00
 */
export const $t = (key: string, value?: { [key: string]: any }) => {
  try {
    if (localStorage.getItem('showI18nKey')) return key;
    if (!value) return I18N.i18nTranslate(key);
    return formatTextMessage(I18N.i18nTranslate(key), value ?? {});
  } catch (e) {
    console.log(e, '文案翻译失败');
    return '';
  }
};

export default $t;
