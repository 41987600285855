import { message } from '@didi-pebble/pebble-design-react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { getPassportUrl } from '@/lib/passport';
import { JsonParse, JsonStringify } from '@/utils';
import { MenuConfig, MenuDict } from '@/components/layouts/page/config';
import { SessionType } from '@/configs';
import { InfoRes } from '@/api/common.type';

import { Omega } from './omega';

export interface CustomRequestConfig extends AxiosRequestConfig {
  /* 是否需要返回完整的response */
  allRes?: boolean;
  /* 统一显示接口异常Toast */
  errorToast?: boolean;
  /* 不抛出错误 */
  allResolve?: boolean;
  /* 需要走风控的接口 */
  riskControl?: boolean;
  /* 防抖标识 */
  requestSymbol?: string;
}
/**
 * 统一返回结构体 根据后端规范
 */
export interface CommonRes<T> {
  data: T;
  errno: number;
  errmsg: string;
}

const axiosInstance = axios.create({
  baseURL: './api/'
});

export const SuccessCode = 0;

export enum ApiErrorCode {
  loginInvalid = 401, // 登录失效
  systemError = -1, // 后端系统异常
  paramsError = 1001, // 参数错误
  createOrder_priceUpdate = 4002, // 下单接口，价格更新
  newDeliverConfig_toPay = 6001, // 发单页配置接口，有待支付订单
  newDeliverOutTime = 3010, // 不在开城时间内
  billOverdueAndUnpaid = 6005, // 账单超期未支付，错误码
  boletoNotEnough = 6004 // boleto余额不足
}
/**
 * 统一错误处理
 */

function commonHandleError(response: AxiosResponse) {
  const config = response?.config as CustomRequestConfig;

  Omega.trackApiError({
    base: config?.baseURL,
    path: config?.url,
    params: {
      'Didi-Header-Rid': response?.headers?.['didi-header-rid'],
      Date: response?.headers?.date,
      'B2c-Web-Version': localStorage.getItem('b2c_web_version')
    },
    status: response?.status,
    code: response?.data?.errno,
    msg: response?.data?.errmsg || response?.statusText,
    traceId: response?.headers?.['didi-header-rid'] || response?.data?.traceId // 兼容客服接口
  });

  // 其它错误逻辑
  if (
    /**
     * 接口401
     * 访问首页，不跳转；访问其他页面，跳转登录
     */
    response?.data?.errno === ApiErrorCode.loginInvalid &&
    config?.headers?.pathname !== MenuConfig[MenuDict.Root].routePath // 根路由不需要跳转passport
  ) {
    sessionStorage.removeItem(SessionType.windowLeaveValue);
    window.location.href = getPassportUrl().login;
  }
  if (config?.errorToast) {
    // 显示错误信息
    message.error(response?.data?.errmsg);
  }
}

class DebounceTag {
  static requestMaps: {
    [key: string]: string;
  } = {};
}
/**
 * 请求拦截
 */
axiosInstance.interceptors.request.use(
  (config: any) => {
    if (config?.requestSymbol) {
      const debounceId = `${config.method}${config.url}`;
      const requestSymbol = `${config.method}${config.url}${new Date().getTime()}`;

      DebounceTag.requestMaps[debounceId] = requestSymbol;
      config.requestSymbol = requestSymbol;
    }
    const newConfig = { ...config };
    const userInfo: InfoRes = JsonParse(localStorage.getItem('userInfo') as string);
    /* 公共参数处理 */
    const commonParams: any = {
      headers: {
        pathname: JsonParse(sessionStorage.getItem('location'))?.pathname,
        'didi-header-hint-content': JsonStringify({
          location_country: 'BR',
          lang: JsonParse(localStorage.getItem('commonLang'))?.lang?.value || 'pt-BR',
          locale: JsonParse(localStorage.getItem('commonLang'))?.lang?.value || 'pt_BR',
          city_id: JsonParse(localStorage.getItem('commonCity') as string)?.city?.city_id
        }),
        'b2c-web-version': localStorage.getItem('b2c_web_version') || undefined
        // 补充公共请求头
      },
      params: {
        company_id: userInfo?.companies?.[0]?.id
        // 补充公共请求参数
      }
    };

    newConfig.headers = {
      ...commonParams.headers,
      ...config.headers
    };
    newConfig.params = {
      ...commonParams.params,
      ...config.params
    };

    /** 需要风控的接口 */
    if ((config as CustomRequestConfig)?.riskControl) {
      const riskParams = {
        lat: Number(localStorage.getItem('user_lat')) || undefined,
        lng: Number(localStorage.getItem('user_lng')) || undefined,
        device_id: localStorage.getItem('web_id'),
        web_id: localStorage.getItem('web_id'),
        network_type: (navigator as any)?.connection?.effectiveType || undefined,
        os_type: navigator?.platform || undefined,
        type: 'B2C',
        cookie_enabled: navigator.cookieEnabled,
        online: navigator.cookieEnabled,
        platform: navigator.platform,
        language: navigator.language,
        languages: navigator.languages,
        vendor: navigator.vendor,
        color_depth: window.screen.colorDepth,
        height: window.screen.height,
        pixel_depth: window.screen.pixelDepth,
        width: window.screen.width
      };

      newConfig.data = {
        ...{ browser_info: riskParams },
        ...config.data
      };
    }

    return newConfig;
  },
  error => {
    return Promise.reject(error);
  }
);

/**
 * 响应拦截
 */
axiosInstance.interceptors.response.use(
  async response => {
    const resData = response.data;
    const config = response?.config as CustomRequestConfig;

    if (config?.requestSymbol) {
      const debounceId = `${config.method}${config.url}`;

      if (DebounceTag.requestMaps?.[debounceId] !== config?.requestSymbol) {
        return new Promise(() => {});
      }
    }

    if (config.allResolve) {
      return Promise.resolve(response);
    }

    if (String(resData?.errno) !== String(SuccessCode)) {
      commonHandleError(response);
    }
    if (
      [String(ApiErrorCode.systemError), String(ApiErrorCode.paramsError)].includes(
        String(resData?.errno)
      )
    ) {
      // 参数错误 和 系统错误 当做httpError来处理
      return Promise.reject(config?.allRes ? response : resData);
    }
    return Promise.resolve(config?.allRes ? response : resData);
  },
  error => {
    const config = error?.response?.config as CustomRequestConfig;

    // 处理轮询接口防抖，仅接收最后一次请求
    if (config?.requestSymbol) {
      const debounceId = `${config.method}${config.url}`;

      if (DebounceTag.requestMaps?.[debounceId] !== config?.requestSymbol) {
        return new Promise(() => {});
      }
    }
    commonHandleError(error?.response);
    return Promise.reject(error);
  }
);
/**
 * 请求服务API
 */
export function Http<T>(config: CustomRequestConfig): Promise<CommonRes<T>> {
  return axiosInstance(config);
}
